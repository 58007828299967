import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import dateFormat from "dateformat";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loader from "../../../Loader/Loader";

const MobileSilderDetails = () => {
    const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/slider/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data[0]);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  } else {
    return (
      <>
        <div className="container">
        <div className="mobile-slider-details-section mobile-news-details-section news-details-area">
          <div className="news-details">
            <main>
              <div className="news-details-main">
                <LazyLoadImage
                  alt="img"
                  effect="blur"
                  width="32"
                  height="32"
                  className=""
                  src={`https://api.prospectstreetx.com/${data?.image_url}`}
                />
                 <div className="news-details-time">
                  <span className="pt-0">{dateFormat(data.createdAt, "d/m/yyyy")}</span>
                </div>
                <div className="news-title pt-2">
                  <h5>{data?.title}</h5>
                </div>
               
                <div className="news-detail-all">
                  <p className="mb-2 news-detail-short_dis">{data?.short_dis}</p>
                  {data?.dis ? HTMLReactParser(data?.dis) : ""}
                </div>
              </div>
            </main>

          </div>
        </div>
        </div>
      
      </>
    );
  }
};
export default MobileSilderDetails;