import React from "react";
import { NavLink } from "react-router-dom";
import "./MenuIndicator/MenuIndicator.css";

const MobileUserFooter = () => {
  return (
    <>
      <div className="menu-indicator-navigation">
        <ul>
          <li className="list-link">
            <NavLink to="/user/dashboard" end>
              <span className="icon">
                <i className="fa-solid fa-house"></i>
              </span>
              <span className="text">Home</span>
            </NavLink>
          </li>

          <li className="list-link">
            <NavLink to="/user/markets" end>
              <span className="icon">
                <i className="fa-solid fa-square-poll-vertical"></i>
              </span>
              <span className="text">Markets</span>
            </NavLink>
          </li>
          <li className="list-link">
            <NavLink to="/user/trade" end>
              <span className="icon">
                <i className="fa-solid fa-chart-pie"></i>
              </span>
              <span className="text">Trade</span>
            </NavLink>
          </li>

          <li className="list-link">
            <NavLink to="/user/news" end>
              <span className="icon">
                <i className="fa-regular fa-newspaper"></i>
              </span>
              <span className="text">News</span>
            </NavLink>
          </li>
          <li className="list-link" >
            <NavLink to="/user/assets" end>
              <span className="icon">
                <i className="fa-regular fa-gem"></i>
              </span>
              <span className="text">Assets</span>
            </NavLink>
          </li>
          {/* <div className="indicator"></div> */}
        </ul>
      </div>

      {/* <SupportIcon></SupportIcon> */}
    </>
  );
};

export default MobileUserFooter;
