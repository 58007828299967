import React, { useContext, useEffect, useState } from "react";
import "./UserBalance.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const UserBalance = () => {
  const { authUser } = useContext(AuthContext);

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  return (
    <>
      <section className="user-mobile-balance-section">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="user-balance">
              <p>Total balance (USDT)</p>
              <h5>{!isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}</h5>
              <span>≈ ${!isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}</span>
            </div>
            <Link to="/user/assets/deposit" className="user-btn1">
              Add funds
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserBalance;
