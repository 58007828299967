import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';
import SingleItem from './SingleItem';
import { DatePicker } from "antd";
import dateFormat from "dateformat";
import CustomPaginationMobile from '../../../../CustomPagination/CustomPaginationMobile';
import Loader from '../../../../Loader/Loader';

const MobileTransactionHistory = () => {
    const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);

    useEffect(() => {
        if (authUser?._id) {
            fetch(`https://api.prospectstreetx.com/api/user/transaction/view/${authUser?._id}`, {
                method: 'GET',
            })
                .then(res => res.json())
                .then(data => {
                    setData(data.data);
                    setPaginateData(data);
                    setLoading(false);

                });


        }

    }, [authUser?._id])

    const [dateValue, setDateValue] = useState([]);
    const [dateValueShow, setDateValueShow] = useState([]);
    const { RangePicker } = DatePicker;

    const Time = () => {
        return (
            <>
                <RangePicker
                    value={dateValueShow}
                    onChange={(e) => {
                        setDateValue(e.map(item => {
                            return dateFormat(item, "isoUtcDateTime")
                        }));
                        setDateValueShow(e);
                    }}
                />
            </>
        );
    };

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api.prospectstreetx.com/api/user/transaction/view/${authUser?._id}?page=${pageNumber}&start_date=${dateValue[0]}&end_date=${dateValue[1]}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };



    if (loading) {
        return (
            <>
               <Loader />
            </>
        );

    } else {

        return (
            <>
                <section className='mobile-version user-login-mobile'>
                    <div className="user-login-title py-2">
                        <div className="container-custom">
                            <div className='history-filter'>
                                <div className='d-flex justify-content-end'>
                                    <Time className="datepicker-here form-control " />
                                    <button className="btn btn-primary px-2" onClick={handlePage}>Filter</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="user-mobile-login-form" >
                        <div className="container-custom py-3">
                            <div >
                                <div className="mobile-future-coin-items py-2">
                                    <section >
                                        <div className="mobile-history-table">
                                            <div className="user-deposit-history">

                                                <div className="user-referral-history-area  ">
                                                    <div className="user-referral-history-items trade-history-table">
                                                        <table className='user-referral-history-table ' >
                                                            <thead>
                                                                <tr className="table-headers">
                                                                    <th>Transaction</th>
                                                                    <th>Transacted</th>
                                                                    <th>Amount</th>
                                                                    <th>Post&nbsp;Balance</th>
                                                                    <th>Details</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.length !== 0 ? (
                                                                        data.map((data, index) => {
                                                                            if (data) {
                                                                                return (
                                                                                    <SingleItem
                                                                                        data={data} index={index} key={data?._id} paginateData={paginateData}
                                                                                    ></SingleItem>
                                                                                );
                                                                            }
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td className="text-muted text-center" colSpan="100%">
                                                                                No records available
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* paginateLinks */}
                                                    <CustomPaginationMobile data={paginateData} handlePage={handlePage}>
                                                    </CustomPaginationMobile>
                                                    {/* paginateLinks */}
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>

        );

    }
};

export default MobileTransactionHistory;