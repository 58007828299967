import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const SingleItem = ({ data }) => {
  const [SingleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data?.slider_id) {
      axios
        .get(`https://api.prospectstreetx.com/api/user/slider/view/${data?.slider_id}`)
        .then((res) => {
          setSingleData(res?.data?.data[0]);
          setLoading(false);
        })
        .catch((err) => {});
    }
  }, [data?.slider_id]);
  if (loading) {
    return;
  }
  return (
    <>
      <Link to={`/user/slider/details/${SingleData?._id}`}>
        <LazyLoadImage
          alt="img"
          effect="blur"
          src={`https://api.prospectstreetx.com/${SingleData?.image_url}`}
        />
      </Link>
    </>
  );
};

export default SingleItem;
