import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ListSingleItems = ({ data }) => {
  return (
    <>
      <div className="mobile-news-lists-item">
        <LazyLoadImage
          alt="img"
          effect="blur"
          src={`https://api.prospectstreetx.com/${data?.image}`}
        />
        <div className="news-content">
          <h4>
            {(data?.title).slice(0, 70)} {(data?.title).length >= 70 && "....."}
          </h4>
          <p>{(data?.dis).slice(0, 150)} {(data?.dis).length >= 150 && "....."}</p>
          <span>Posted On {dateFormat(data?.createdAt, "mmmm dd, yyyy")}</span>
          <Link to={`/user/news/details/${data?._id}`}>Read More</Link>
        </div>
      </div>
    </>
  );
};

export default ListSingleItems;
