import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Forex = ({handleClose}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/trade/market/forex/mobile/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);



  if(loading){
    return;
  }

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
            {data.map((data, index) => (
              <SingleItem
              handleClose={handleClose}
                data={data}
                key={data?._id}
                index={index}
              ></SingleItem>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Forex;
