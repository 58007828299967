import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
const SingleItem = ({ data, HandleModalShow, index, paginateData }) => {
  const [coinData, setCoinData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/new/coin/view/${data?.coin_id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCoinData(data.data);
      });
  }, []);

  return (
    <>
      <div
        className="mobile-history-item"
        onClick={() => HandleModalShow(data)}
      >
        <div className="mobile-history-item-left">
          <div className="mobile-history-item-sn">
            <p>S.N</p>
            <span>
              #
              {parseFloat(index + 1) +
                parseFloat(paginateData?.page) *
                  parseFloat(paginateData?.limit) -
                10}
            </span>
          </div>
          <div>
            <p>{coinData?.name}</p>
            <span>{data?.type}</span>
          </div>
        </div>

        <div className="mobile-history-item-right">
          <p>{data?.coin_amount}</p>
          <span>
            {dateFormat(data?.createdAt, "mmm,")}&nbsp;
            {dateFormat(data?.createdAt, "dS")}&nbsp;
            {dateFormat(data?.createdAt, "yyyy")}
          </span>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
