import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const SingleItem = ({ data, handleClose}) => {
  const [SingleData, setSingleData] = useState([]);
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.indices_id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/trade/market/indices/view/${data?.indices_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data.data);
          if (data.data) {
            const Symbol = `${data?.data?.symbol}`;
            fetchData(Symbol);
          }
        });
    }
  }, [data?.indices_id]);

  const fetchData = async (Symbol) => {
    if (Symbol) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=SA2ZwXn7pBYC-odwXamW`
      );
      const apiResponse = await data.json();
      if (apiResponse?.quotes) {
        setLivePrice(apiResponse?.quotes[0]);
        setLoading(false);
      }
    }
  };
  if (loading) {
    return;
  }
    return (
        <>
              <li>
              <NavLink 
              onClick={handleClose}
                className="menu-item "
                to={`/user/trade/indices/${SingleData?.symbol}/${SingleData?._id}`}
              >
                <div className="d-flex justify-content-between gap-1">
                  <div className="text-xl">
                    <span>{SingleData?.name}</span>
                    <span className="opacity-50 text-base font-light">
                    <br />  {SingleData?.symbol}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                    {livePrice?.bid}$
                    </div>
                  </div>
                </div>
              </NavLink>
            </li> 
        </>
    );
};

export default SingleItem;