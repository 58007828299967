import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import Loader from "../../../Loader/Loader";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import InvestModal from "../../../Page/InvestPage/InvestModal/InvestModal";
import axios from "axios";

const MobileInvestmentPage = () => {
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`https://api.prospectstreetx.com/api/user/investment/plan/view`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setData(data?.data);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/investment/plan/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState("");

  const handlePage = (event) => {
    try {
      if (event?.target?.value) {
        setFilter(event?.target?.value);
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      if (event?.target?.value) {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/investment/plan/filter/view?&page=1&filter=${event?.target?.value}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/investment/plan/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Modalshow, setModalshow] = useState(false);
  const [SingleData, setSingleData] = useState([]);

  const handleModal = (id) => {
    if (id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/investment/plan/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
        });
    }
    setModalshow(true);
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  } else {
    return (
      <>
        <InvestModal
          className="posce-trade-modal"
          show={Modalshow}
          singledata={SingleData}
          setmodalshow={setModalshow}
          onHide={() => setModalshow(false)}
        />

        <section className="investment-plan-section mobile-investment-plan-section">
          <div className="planwrap">
            <div className="container">
              <div className="demo">
                <div className="row gy-3">
                  {data?.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <SingleItem
                            data={data}
                            index={index}
                            key={data._id}
                            authUser={authUser}
                            handleModal={handleModal}
                          ></SingleItem>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div className="text-center h-50">
                        <h6> Data not found</h6>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default MobileInvestmentPage;
