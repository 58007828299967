import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const AnnouncementModal = ({ setShow, singleDataId, show }) => {
  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShow(false);
    setSingleData([]);
    setLoading(false);
  };
  useEffect(() => {
    if (singleDataId) {
      fetch(
        `https://api.prospectstreetx.com/api/frontend/home/notices/view/${singleDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [singleDataId]);

  function ModalComponent(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="announcement-modal-show notices-modal-show "
        backdrop="static"
      >
        <div className="announcement-header">
          <h4>Announcement</h4>
        <button  onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
          {/* <Modal.Header closeButton>
          <Modal.Title>Announcement</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <LazyLoadImage
            alt="img"
            effect="blur"
            src={`https://api.prospectstreetx.com/${singleData?.image}`}
          />

          <div className="container">
            <p>{singleData?.name}</p>
            <span>{singleData?.short_dis}</span>
            <Link to={`/user/announcement/details/${singleData?._id}`}>
              Read More
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
        
        </Modal.Footer>
      </Modal>
    );
  }
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <ModalComponent show={show} onHide={() => setShow(false)} />
      </>
    );
  }
};

export default AnnouncementModal;
