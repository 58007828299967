import React, { useEffect, useState } from "react";
import {  Modal } from "react-bootstrap";
import dateFormat from 'dateformat';
const DepositHistoryDetails = ({ setShow, singleDataId, show }) => {
  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShow(false);
    setSingleData([]);
    setLoading(false);
  };
  useEffect(() => {
    if (singleDataId) {
      fetch(
        `https://api.prospectstreetx.com/api/user/payment/gateways/manual/single/view/${singleDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [singleDataId]);

  function ModalComponent(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="announcement-modal-show notices-modal-show "
        backdrop="static"
      >
        <div className="announcement-header">
          <h4>Details</h4>
        <button  onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <Modal.Body>
       
         <div className="history-details-modal-area">
         <div className="container">
         <div className="history-details-modal-item">
                <p>Network&nbsp;Type</p>
                <span>{singleData?.NetworkType}</span>
            </div>
         <div className="history-details-modal-item">
                <p>Amount</p>
                <span>${singleData?.Amount}</span>
            </div>
         <div className="history-details-modal-item">
                <p>Transaction</p>
                <span>{singleData?.Transaction}</span>
            </div>
         <div className="history-details-modal-item">
                <p>Date</p>
                <span>{dateFormat(singleData?.createdAt, "mmm,")}&nbsp;{dateFormat(singleData?.createdAt, "dS")}&nbsp;{dateFormat(singleData?.createdAt, "yyyy")}</span>
            </div>
         <div className="history-details-modal-item">
                <p>Progress</p>
                {singleData?.Status === 0 && <span className="badge bg-warning ">pending</span> }
                    {singleData?.Status === 1 && <span className="badge bg-success ">accept</span> }
                    {singleData?.Status === 2 && <span className="badge bg-danger ">rejected</span> }
            </div>


         </div>
           
         </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
        
        </Modal.Footer>
      </Modal>
    );
  }
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <ModalComponent show={show} onHide={() => setShow(false)} />
      </>
    );
  }
};

export default DepositHistoryDetails;
