import React, { useEffect, useState } from "react";
import "./MobileSideBarMenu.css";
import { Link } from "react-router-dom";
import user from "./../user.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MobileLogoutCompnent from "../../../Auth/Logout/MobileLogoutCompnent";
const MobileSideBarMenu = ({ handleShow,  authUser }) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  return (
    <>
      <div id="more_div" className="mobile-sidebar-menu-area">
        <div className="mobile-sidebar-menu-close-btn" onClick={handleShow}>
          <span id="more_off" className="more_div_div_span">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z"></path>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </span>
        </div>
        <div className="more_div_div">
          {/* <!--========= Mobile view menu offcanvas =========--> */}
          <div className="mobile-sidebar-nav-area ">
            {!loading &&  <div className="d-flex nav_div_un ">
              <div className="nav_div_un_div_un">
                <h4 style={{ color: "orange", fontSize: "14px" }}>
                  <b>
                    {userData?.fname} {userData?.lname}
                  </b>
                </h4>
                <h6 style={{ color: "gray", fontSize: "12px" }}>
                  UID: {userData?.uid}
                </h6>
                <h6 style={{ color: "gray", fontSize: "12px" }}>
                  Credit Score: 10
                </h6>
                <h6 style={{ color: "gray", fontSize: "12px" }}>
                  Balance: {!isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}
                </h6>
              </div>
              <div className="nav_div_un_div_deux rounded-full user_peofile">
                {userData ? (
                  <>
                    <LazyLoadImage
                      effect="blur"
                      src={user}
                      alt="user"
                      width="80%"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>}
           
            <hr
              style={{
                width: "100%",
                backgroundColor: "#2b3139",
                marginBottom: "0px",
              }}
            />
            <div style={{ marginTop: "0px" }} className="mobile_canvas_menu">
              <ul className="side_menu">
              <li>
                  <Link
                    className="menu-item "
                    to="/user/dashboard"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "6px",
                        backgroundColor: "blue",
                        borderRadius: "25px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fa text-white fa-home"></i>
                    </span>
                    Home
                  </Link>
                  </li>
                  <li>
                  <Link
                    className="menu-item "
                    to="/user/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#F44336",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-chart-line"></i>
                    </span>
                    History
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/new/coin/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#F44336",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-chart-line"></i>
                    </span>
                    New Coin History
                  </Link>
                </li>
                {/* 
              
                <li>
                  <Link
                    className="menu-item "
                    to="/user/markets"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#F44336",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-chart-line"></i>
                    </span>
                    Market
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item active"
                    to="/user/trade"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#9C27B0",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-exchange-alt"></i>
                    </span>
                    Trade
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item active"
                    to="/user/trade/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#9C27B0",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-exchange-alt"></i>
                    </span>
                    Trade History
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item "
                    to="/user/bitcoin/mining"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fa-credit-card"></i>
                    </span>
                    Bitcoin Mining
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/bitcoin/mining/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fa-credit-card"></i>
                    </span>
                    Bitcoin Mining History
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item "
                    to="/user/gold/mining"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Gold Mining
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/gold/mining/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Gold Mining History
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/invest/plan"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Invest Plan
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/invest/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Invest Plan History
                  </Link>
                </li>

                <hr
                  style={{
                    width: "100%",
                    backgroundColor: "#2b3139",
                    marginBottom: "0px",
                  }}
                />
                <li>
                  <Link
                    className="menu-item "
                    to="/user/assets/deposit"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FF69B4",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white  fa-credit-card"></i>
                    </span>
                    Deposit
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/deposit/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FF69B4",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white  fa-credit-card"></i>
                    </span>
                    Deposit History
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item "
                    to="/user/assets/withdraw"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#D2691E",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Withdrawal
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/withdraw/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#D2691E",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Withdrawal History
                  </Link>
                </li>
                <hr
                  style={{
                    width: "100%",
                    backgroundColor: "#2b3139",
                    marginBottom: "0px",
                  }}
                />
                <li>
                  <Link
                    className="menu-item"
                    to="/user/apply/loan"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#000000",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Apply for a loan
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item"
                    to="/user/apply/loan/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#000000",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Loan History
                  </Link>
                </li>

                <li>
                  <Link className="menu-item" to="#" onClick={handleShow}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#DAA520",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-file-alt"></i>
                    </span>
                    New Coin
                  </Link>
                </li>

                <li>
                  <Link className="menu-item" to="#" onClick={handleShow}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#DAA520",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-file-alt"></i>
                    </span>
                    New Coin History
                  </Link>
                </li>

                <hr
                  style={{
                    width: "100%",
                    backgroundColor: "#2b3139",
                    marginBottom: "0px",
                  }}
                /> */}
                <li>
                  <Link
                    className="menu-item "
                    to="/user/profile/update"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "tomato",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-user"></i>
                    </span>
                    Profile
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item"
                    to="/user/contact"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#4CAF50",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa fa-headphones"></i>
                    </span>
                    Customer Services
                  </Link>
                </li>
                <li>
                  <Link className="menu-item" to="/user/platform/apps" onClick={handleShow}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "indigo",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa fa-download"></i>
                    </span>
                    Platform Apps
                  </Link>
                </li>
               
<MobileLogoutCompnent />
          
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSideBarMenu;
