import React, { useRef, useState } from "react";
import Crypto from "./Crypto/Crypto";
import StocksPage from "./Stocks/StocksPage";
import EnergisesPage from "./Energises/EnergisesPage";
import MetalsPage from "./Metals/MetalsPage";

const Markets = () => {
  const [marketShow, setMarketShow] = useState("Crypto");

  const MarketShowHandle = (data) => {
    switch (data) {
      case "Stocks":
        setMarketShow("Stocks");
        break;

      case "Crypto":
        setMarketShow("Crypto");
        break;

      case "Energises":
        setMarketShow("Energises");
        break;

      case "Metals":
        setMarketShow("Metals");
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="user-mobile-market market-area-sw mobile-market-view">
        <div className="  mobile-view">
          <div className="container-fluid">
            <ul className="nav nav-pills mt-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Crypto" ? "nav-link active" : "nav-link "
                  }
                  id="pills-top-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-top"
                  type="button"
                  role="tab"
                  aria-controls="pills-top"
                  aria-selected="true"
                  onClick={() => MarketShowHandle("Crypto")}
                >
                  Cryptocurrency
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Metals" ? "nav-link active" : "nav-link "
                  }
                  id="pills-stocks-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-stocks"
                  type="button"
                  role="tab"
                  aria-controls="pills-stocks"
                  aria-selected="false"
                  tabindex="-1"
                  onClick={() => MarketShowHandle("Metals")}
                >
                  Metal &amp; Energises
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Stocks" ? "nav-link active" : "nav-link "
                  }
                  id="pills-metals-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-metals"
                  type="button"
                  role="tab"
                  aria-controls="pills-metals"
                  aria-selected="false"
                  tabindex="-1"
                  onClick={() => MarketShowHandle("Stocks")}
                >
                  Stock &amp; Indices
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Energises" ? "nav-link active" : "nav-link "
                  }
                  id="pills-forex-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-forex"
                  type="button"
                  role="tab"
                  aria-controls="pills-forex"
                  aria-selected="false"
                  tabindex="-1"
                  onClick={() => MarketShowHandle("Energises")}
                >
                  Forex
                </button>
              </li>
            </ul>
 
            {marketShow === "Crypto" && <Crypto></Crypto>}
            {marketShow === "Stocks" && <StocksPage></StocksPage>}
            {marketShow === "Energises" && <EnergisesPage></EnergisesPage>}
            {marketShow === "Metals" && <MetalsPage></MetalsPage>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Markets;
