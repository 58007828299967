import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from "react-router-dom";
const SideBarMenu = ({  ToggleUserSidebar }) => {
  const { LoginWithEmail, authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/transaction/available/all/balance/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }
 
  }, [authUser?._id, ToggleUserSidebar]);



  const from = "/login";
  const navigate = useNavigate();
  const [remove, setremove] = useState(false);
  function getCookie(name) {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  }

  let token = getCookie("gffex_token");
  const SignOut = () => {
    document.cookie = `gffex_token=; expires=${new Date(
      0
    ).toUTCString()}; path=/`;
    localStorage.removeItem("ID");
    const data = null;
    LoginWithEmail(data);
    if (authUser === null) {
      navigate(from, { replace: true });
    }
    setremove(true);
  };
  const logout = localStorage.getItem("ID");

  useEffect(() => {
    if (!logout && remove === true) {
      navigate(from, { replace: true });
    }
  }, [remove, logout, navigate]);
 
  return (
    <>
      <div>
        <div className="container">
          <div className="close-btn" onClick={ToggleUserSidebar}>
            <i className="fa fa-times"></i>
          </div>
        </div>
        {loading ? (
          <>
            <Skeleton height={10} count={4} />
          </>
        ) : (
          <>
            <div className="user-bal-area">
              <div className="user-bal-info">
                <p>Total Balance :</p>
                <span> ${!isNaN(data?.userData?.balance) && parseFloat(data?.userData?.balance).toFixed(2)}</span>
              </div>
              <div className="user-bal-info">
                <p>New coin Balance:</p>
                <span> $0</span>
              </div>
              <div className="user-bal-info">
                <p>Total Deposit:</p>
                <span> ${data?.DeposittBalance}</span>
              </div>
              <div className="user-bal-info">
                <p>Total Withdraw:</p>
                <span className="text-danger"> ${!isNaN(data?.WithdrawalBalance) && parseFloat(data?.WithdrawalBalance).toFixed(2)}</span>
              </div>
            </div>
          </>
        )}

        <div className="mt-2 sidebar-menu">
          <Link to="/dashboard" onClick={ToggleUserSidebar}>
            <div className="header_mobileMenuBtn__KBVfi">
              <span>Dashboard</span>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </Link>
          <Link to="/profile/update" onClick={ToggleUserSidebar}>
            <div className="header_mobileMenuBtn__KBVfi">
              <span>Profile Update</span>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </Link>
          <Link to="/password/update" onClick={ToggleUserSidebar}>
            <div className="header_mobileMenuBtn__KBVfi">
              <span>Change Password</span>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </Link>

          <button onClick={SignOut}>
            <div className="header_mobileMenuBtn__KBVfi">
              <span>Log Out</span>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default SideBarMenu;
