import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import DashBoardSideBar from "../UserDashBoard/DashBoardSideBar";

const KycPage = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState([]);

  const [results, setResults] = useState({});
  const [userfront_page_img, setUserfront_page_img] = useState("");
  const [userback_page_img, setUserback_page_img] = useState("");
  const [dataValue, setDataValue] = useState({});
  const [message, setMessage] = useState("");
  const refSubmitDis = useRef();

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/kyc/verify/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setData(data.data);
        });
    }
  }, [results, authUser?._id]);

  const handlefront_page_img = (e) => {
    setMessage("");
    setUserfront_page_img(e.target.files[0]);
  };
  const handleback_page_img = (e) => {
    setMessage("");
    setUserback_page_img(e.target.files[0]);
  };

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const userData = {
      ...dataValue,
      user_id: authUser?._id,
      front_page_img: userfront_page_img,
      back_page_img: userback_page_img,
    };
    if (userData.front_page_img === "") {
      setMessage("please choose Front part image");
    } else if (userData.back_page_img === "") {
      setMessage("please choose Back part image");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios
        .post(`https://api.prospectstreetx.com/api/kyc/verify/store`, userData, config)
        .then((data) => {
          event.target.reset();
          setResults(data);
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
        });
    }
  };

  if (loading) {
    return (
      <>
        <div className="loader-section d-flex justify-content-center align-items-center">
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </>
    );
  } else {
    if (data?.status === 0) {
      return (
        <>
          <section className="user-dashboard">
            <div className="row">
              <div className="col-3 user-menu-left">
                <DashBoardSideBar></DashBoardSideBar>
              </div>
              <div className="col-9">
                <div class="user-profile-area">
                  <div class="container my-4">
                    <div class="row gutters">
                      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                        <div class="card h-100">
                          <div class="card-body">
                            <form>
                              <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <h6 class="mb-3 text-primary">
                                    KYC verification Information
                                  </h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div class="kyc-details">
                                    <h5>
                                      Full Name: <span>{data?.user_name}</span>
                                    </h5>
                                    <h5>
                                      Certification: <span>{data?.type}</span>
                                    </h5>
                                    <h5>
                                      Front Part Photo:{" "}
                                      <span>
                                        <a
                                          href={`https://api.prospectstreetx.com/${data?.front_img}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Click Here
                                        </a>
                                      </span>
                                    </h5>
                                    <h5>
                                      {" "}
                                      Back Part Photo:{" "}
                                      <span>
                                        <a
                                          href={`https://api.prospectstreetx.com/${data?.back_img}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Click Here
                                        </a>
                                      </span>
                                    </h5>
                                    <h5>
                                      SSN: <span>{data?.ssn}</span>
                                    </h5>
                                    <h5>
                                      Status: <span class="badge bg-warning ">Pending</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />

          <section className="user-dashboard">
            <div className="row">
              <div className="col-3 user-menu-left">
                <DashBoardSideBar></DashBoardSideBar>
              </div>
              <div className="col-9">
                <div class="user-profile-area">
                  <div class="container my-4">
                    <div class="row gutters m-auto">
                      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                        <div class="card h-100">
                          <div class="card-body">
                            <form onSubmit={handleSubmitData}>
                              <div class="row gutters ">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <h6 class="mb-3 text-primary">
                                    KYC verification
                                  </h6>
                                </div>

                                <div class="col-xl-12 col-lg-12col-md-12 col-sm-12 col-12 mb-3">
                                  <div class="form-group">
                                    <label for="fullName">Full Name</label>
                                    <input
                                      type="text"
                                      name="name"
                                      class="form-control"
                                      id="fullName"
                                      placeholder="Enter full name"
                                      required
                                      onBlur={handleInputBlur}
                                    />
                                  </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div class="form-group">
                                    <label class="form-label">
                                      Certification
                                    </label>
                                    <select
                                      class="form-control cmn--form--control"
                                      name="type"
                                      required
                                      onBlur={handleInputBlur}
                                    >
                                      <option value="" disabled selected>
                                        Select One
                                      </option>
                                      <option value="Passport">Passport</option>
                                      <option value="NID">NID</option>
                                      <option value="Driving License">
                                        Driving License
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div class="form-group">
                                    <label class="form-label">
                                      Front Part Photo
                                    </label>
                                    <input
                                      type="file"
                                      class="form-control currency_input cmn--form--control"
                                      name="front_part_photo"
                                      required
                                      accept=" .jpg,  .jpeg,  .png, "
                                      id="fileInput"
                                      onChange={handlefront_page_img}
                                    />
                                    <pre class="text--base mt-1">
                                      Supported mimes: jpg,jpeg,png
                                    </pre>
                                  </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div class="form-group">
                                    <label class="form-label">
                                      Back Part Photo
                                    </label>
                                    <input
                                      type="file"
                                      class="form-control currency_input cmn--form--control"
                                      name="back_part_photo"
                                      required
                                      accept=" .jpg,  .jpeg,  .png, "
                                      id="fileInput"
                                      onChange={handleback_page_img}
                                    />
                                    <pre class="text--base mt-1">
                                      Supported mimes: jpg,jpeg,png
                                    </pre>
                                  </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div class="form-group">
                                    <label for="phone">
                                      SSN <span>(optional)</span>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="phone"
                                      name="ssn"
                                      onBlur={handleInputBlur}
                                    />
                                    <p>Note: only USA and canadian citizen</p>
                                  </div>
                                </div>
                              </div>

                              <div className="row gutters">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="text-right d-flex">
                                            <button type="submit" id="submit" className="btn btn-primary px-5 fw-normal d-block mt-3"  ref={refSubmitDis}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
};

export default KycPage;
