import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import {  Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../../../../../Contexts/AuthContext/AuthProvider";
import TradeCountdown from "../../../TradeCountdown/TradeCountdown";

const LongModal = (props) => {

    const {id, symbol, marketData, longmodalshow, setlongmodalshow} = props;
  //   console.log(props?.symbol);
  const { authUser } = useContext(AuthContext);

  const [thradeSettingData, setthradeSettingData] = useState([]);
  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/admin/trade/setting/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setthradeSettingData(data?.data);
      });
  }, []);

  const [coinPrice, setCoinPrice] = useState();
  useEffect(() => {
    fetch(
      `https://marketdata.tradermade.com/api/v1/live?currency=${symbol}&api_key=M2k43cPLwiLbos80O_AM`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCoinPrice(data?.quotes[0].bid);
      });
  }, [symbol, longmodalshow]);






  const [countdownmodalshow, setcountdownmodalshow] = useState(false);
  
  const [results, setresults] = useState([]);


// submit form 



const [dataValue, setDataValue] = useState({});

const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const refSubmit = useRef();


  const handleSubmitData = (event) => {
    event.preventDefault();
   
    if (coinPrice) {
        refSubmit.current.setAttribute("disabled", true);
      const userData = {
        ...dataValue,
        CryptoCurrency: marketData?._id,
        Crypto_price: coinPrice,
        user_id: authUser?._id,
        HighLow: "High",
      };
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .post(
          `https://api.prospectstreetx.com/api/user/trade/log/metals/store`,
          userData,
          config
        )
        .then((data) => {
          if (data.data.success === true) {
            toast.success(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setresults(data.data.data);
            setcountdownmodalshow(true)
            setlongmodalshow(false);
            refSubmit.current.removeAttribute("disabled");
          } else {
            toast.error(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            refSubmit.current.removeAttribute("disabled");
          }
        })
        .catch((error) => {
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmit.current.removeAttribute("disabled");
        });
    } else {
      toast.error(`Something is wrong try again`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };






  return (
   <>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 class="modal-title">Order Confirmation</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong>   {marketData?.Name ?  marketData?.Name :  marketData?.name } 
        </p>
        <p>
          <strong>Direction:</strong>{" "}
          <span style={{ color: "#fff" }}>Long</span>
        </p>
        <p>
          <strong>Current price:</strong> {coinPrice}
        </p>
        <br />
        <p className="mb-1">
          <strong>Choose period</strong>
        </p>
        <form onSubmit={handleSubmitData}>
          <div class="bargaining__fieldset">
            <div id="timeSelection" class="time-selection mt-2">
              <div class="period-buttons">
                <div className="thradeSettingDataRadio">
                  {thradeSettingData.map((data) => {
                    return (
                      <>
                        {" "}
                        <input
                          type="radio"
                          name="thradeSetting_id"
                          id={data?._id}
                          value={data?._id}
                          key={data?._id}
                          onChange={handleInputBlur}
                        />
                        <label htmlFor={data?._id}>
                          <FontAwesomeIcon icon="fa-regular fa-clock" />{" "}
                          {data?.Time}S
                        </label>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <input
              class="field__input bargaining__field"
              type="number"
              min='10'
              name='amount'
              placeholder="Amount($)"
              required=""
              id="amount"
              onChange={handleInputBlur}
            />
          </div>

          <p>
            <strong>Available Balance: </strong>
            {!isNaN(authUser?.balance) && parseFloat(authUser?.balance).toFixed(2)}$
          </p>

          <button
            className="btn btn-success  w-100 "
            data-bs-toggle="modal"
            style={{ borderRadius: "30px" }}
            ref={refSubmit}
          >
            Long
          </button>
        </form>
      
      </Modal.Body>
    </Modal>
   
    <TradeCountdown
    marketData={marketData}
      data={results}
      show={countdownmodalshow}
      setcountdownmodalshow={setcountdownmodalshow}
      onHide={() => setcountdownmodalshow(false)}
    ></TradeCountdown>
   </>
  );
};

export default LongModal;
