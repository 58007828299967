import React, { useEffect,  useState } from "react";
import axios from "axios";
import GridSingleItems from "./GridSingleItems";
import CustomPaginationMobile from "../../../CustomPagination/CustomPaginationMobile";
import Loader from "../../../Loader/Loader";
import "./News.css";
import ListSingleItems from "./ListSingleItems";
const News = () => {
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/frontend/home/news/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.prospectstreetx.com/api/frontend/home/news/view?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const [ItemsShow, setItemShow] = useState('lists')

 
  const handeSHow = (data) => {
    switch (data) {
      case "lists":
        setItemShow("lists");
        break;
      case "grids":
        setItemShow("grids");
        break;

      default:
        break;
    }
  };

  if(loading){
    return (
      <>
    <Loader />
    </>
    );
  }else{

    return (
      <>
        <main className="mobile-news-section">
          <div className="container">
            <div className="news-breadcrumb">
              <h5>News</h5>
            </div>
         
            <div className="news-view-area">
              <span onClick={() => handeSHow("lists")} className={ItemsShow === "lists" ? "active" :''}>
                <i className="fa-solid fa-list"></i> List view
              </span>
              <span onClick={() => handeSHow("grids")} className={ItemsShow === "grids" ? "active" :''}>
                <i className="fa-solid fa-grip"></i> Grid view
              </span>
            </div>
         
  
            <div className="main-newes-area">
              {ItemsShow === "lists" && <div className="news-lists-area" >
              {data.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <ListSingleItems
                            data={data}
                            index={index}
                            key={data._id}
                            paginateData={paginateData}
                          ></ListSingleItems>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td className="text-muted text-center" colspan="100%">
                        Data not found
                      </td>
                    </tr>
                  )}
              </div> }
              
              {ItemsShow === "grids" &&    <div
                className="mobile-news-grid-area news-grid-area"
                
              >
                <div className="row row-cols-1 row-cols-md-3 g-4">
                {data.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <GridSingleItems
                            data={data}
                            index={index}
                            key={data._id}
                            paginateData={paginateData}
                          ></GridSingleItems>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td className="text-muted text-center" colspan="100%">
                        Data not found
                      </td>
                    </tr>
                  )}
                </div>
              </div> }
           
  
                  {/* paginateLinks */}
                  <CustomPaginationMobile
                data={paginateData}
                handlePage={handlePage}
              ></CustomPaginationMobile>
              {/* paginateLinks */}
            </div>
          </div>
        </main>
      </>
    );
  }



};
export default News;