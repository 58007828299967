import React, { useRef, useState } from 'react';
import WithdrawalHistroy from '../../../Page/User/TransactionHistory/WithdrawalHistroy/WithdrawalHistroy';
import MobileDepositHistory from './MobileDepositHistory/MobileDepositHistory';
import MobileWithdrawalHistroy from './MobileWithdrawalHistroy/MobileWithdrawalHistroy';
import MobileTransactionHistory from './MobileTransactionHistory/MobileTransactionHistory';

const MobileTransaction = () => {


    const refWithdrawalHistroyShow = useRef();
    const refWithdrawalHistroyClassAdd = useRef();
    const refDepositHistoryShow = useRef();
    const refDepositHistoryClassAdd = useRef();
    const refTransactionHistoryShow = useRef();
    const refTransactionHistoryClassAdd = useRef();

    const [Withdrawal, setWithdrawal] = useState(false);
    const [Transaction, setTransaction] = useState(false);


    const handleMenuItems = (data) => {
        if (data === 'WithdrawalHistroy') {
            refWithdrawalHistroyShow.current.style.display = "block";
            refDepositHistoryShow.current.style.display = "none";
            refTransactionHistoryShow.current.style.display = "none";
            refWithdrawalHistroyClassAdd.current.classList.add("active-markets-content-menu");
            refDepositHistoryClassAdd.current.classList.remove("active-markets-content-menu");
            refTransactionHistoryClassAdd.current.classList.remove("active-markets-content-menu");
            setWithdrawal(true);

        }

        if (data === 'DepositHistory') {
            refDepositHistoryShow.current.style.display = "block";
            refWithdrawalHistroyShow.current.style.display = "none";
            refTransactionHistoryShow.current.style.display = "none";
            refDepositHistoryClassAdd.current.classList.add("active-markets-content-menu");
            refWithdrawalHistroyClassAdd.current.classList.remove("active-markets-content-menu");
            refTransactionHistoryClassAdd.current.classList.remove("active-markets-content-menu");


        }
        if (data === 'TransactionHistory') {
            refTransactionHistoryShow.current.style.display = "block";
            refDepositHistoryShow.current.style.display = "none";
            refWithdrawalHistroyShow.current.style.display = "none";
            refTransactionHistoryClassAdd.current.classList.add("active-markets-content-menu");
            refWithdrawalHistroyClassAdd.current.classList.remove("active-markets-content-menu");
            refDepositHistoryClassAdd.current.classList.remove("active-markets-content-menu");
            setTransaction(true);


        }

    }

    return (
        <>
            <section className=" mobile-py-20-120 mobile-version pt-3">
                <div className="container-custom">
                    <div className="Markets-Menu-section">
                        <h4 className='active-markets-content-menu' ref={refDepositHistoryClassAdd} onClick={() => handleMenuItems('DepositHistory')}>Deposit </h4>
                        <h4 onClick={() => handleMenuItems('WithdrawalHistroy')} ref={refWithdrawalHistroyClassAdd}>
                            Withdrawal
                        </h4>
                        <h4 onClick={() => handleMenuItems('TransactionHistory')} ref={refTransactionHistoryClassAdd}>
                            Transaction
                        </h4>
                    </div>
                </div>


                {/* Markets Content section  */}
                <div ref={refDepositHistoryShow}>
                    <MobileDepositHistory></MobileDepositHistory>
                </div>

                <div ref={refWithdrawalHistroyShow} style={{ display: 'none' }}>
                {(() => {
                        if (Withdrawal) {
                            return (
                                <MobileWithdrawalHistroy></MobileWithdrawalHistroy>
                            )
                        }
                    })()}
                </div>
                <div ref={refTransactionHistoryShow} style={{ display: 'none' }}>
                    {(() => {
                        if (Transaction) {
                            return (
                                <MobileTransactionHistory></MobileTransactionHistory>
                            )
                        }
                    })()}
                </div>
            </section>
        </>
    );
};

export default MobileTransaction;