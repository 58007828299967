import React, { useState } from "react";
import "./MobileTradePage.css";
import Crypto from "./Market/Crypto/Crypto";
import Metal from "./Market/Metal/Metal";
import Energises from "./Market/Energises/Energises";
import Stock from "./Market/Stock/Stock";
import Indices from "./Market/Indices/Indices";
import Forex from "./Market/Forex/Forex";

const MobileTradePage = () => {
  const [marketShow, setMarketShow] = useState("Crypto");

  const MarketShowHandle = (data) => {
    switch (data) {
      case "Crypto":
        setMarketShow("Crypto");
        break;
      case "Metal":
        setMarketShow("Metal");
        break;
      case "Energises":
        setMarketShow("Energises");
        break;
      case "Stock":
        setMarketShow("Stock");
        break;
      case "Forex":
        setMarketShow("Forex");
        break;
      case "Indices":
        setMarketShow("Indices");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <section className="mobile-user-trade-section">
        <div className="d-lg-none">
          <div className="bg-all mobile-view d-block d-lg-none">
            <section className="section_two">
              <div className="section_div container">
                <div className="section_div_div crypto">
                  <button
                    onClick={() => MarketShowHandle("Crypto")}
                    className="section_div_btn"
                  >
                    <i
                      className="fa-brands fa-bitcoin section_div_btn_svg"
                      style={{ color: "blue" }}
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Crypto")}
                    style={{ color: "blue" }}
                    className={
                      marketShow === "Crypto"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                  >
                    Cryptocurrency
                  </h6>
                </div>

                <div className="section_div_div metal">
                  <button
                    onClick={() => MarketShowHandle("Metal")}
                    className="section_div_btn"
                  >
                    <i
                      style={{ color: "#ff1493" }}
                      className="fas bg-white section_div_i  fa-industry section_div_btn_svg"
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Metal")}
                    className={
                      marketShow === "Metal"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Metal
                  </h6>
                </div>

                <div className="section_div_div energises">
                  <button
                    onClick={() => MarketShowHandle("Energises")}
                    className="section_div_btn"
                  >
                    <svg
                      style={{ color: "blue" }}
                      className="section_div_btn_svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
                      <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
                      <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Energises")}
                    className={
                      marketShow === "Energises"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Energises
                  </h6>
                </div>
              </div>
              <div className="section_div_two container">
                <div className="section_div_div stock">
                  <button
                    onClick={() => MarketShowHandle("Stock")}
                    className="section_div_btn"
                  >
                    <i
                      className="fa-brands fa-meta section_div_btn_svg"
                      style={{ color: "orange" }}
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Stock")}
                    className={
                      marketShow === "Stock"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Stock
                  </h6>
                </div>

                <div className="section_div_div indices">
                  <button
                    onClick={() => MarketShowHandle("Indices")}
                    className="section_div_btn"
                  >
                    <svg
                      style={{ color: "green" }}
                      className="section_div_btn_svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z"></path>
                      <path d="M12.971 3.54l6 3.333A2 2 0 0120 8.62v6.536a2 2 0 0 1 -1.029 1.748l-6 3.333a2 2 0 0 1 -1.942 0l-6-3.333A2 2 0 014 15.157V8.62a2 2 0 0 1 1.029 -1.748l6-3.333a2 2 0 0 1 1.942 0z"></path>
                    </svg>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Indices")}
                    className={
                      marketShow === "Indices"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Indices
                  </h6>
                </div>

                <div className="section_div_div forex">
                  <button
                    onClick={() => MarketShowHandle("Forex")}
                    className="section_div_btn"
                  >
                    <i
                      className="fa-solid fa-sack-dollar section_div_btn_svg"
                      style={{ color: "orangered" }}
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Forex")}
                    className={
                      marketShow === "Forex"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Forex
                  </h6>
                </div>
              </div>
            </section>
            <div className="tab-content">
              {marketShow === "Crypto" && <Crypto></Crypto>}
              {marketShow === "Metal" && <Metal></Metal>}
              {marketShow === "Energises" && <Energises></Energises>}
              {marketShow === "Stock" && <Stock></Stock>}
              {marketShow === "Indices" && <Indices></Indices>}
              {marketShow === "Forex" && <Forex></Forex>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileTradePage;
