import React, { useContext, useEffect, useState } from "react";
import "./INVESTMENTPLANS.css";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import InvestModal from "../InvestModal/InvestModal";
import Loader from "../../../Loader/Loader";
import EXCHANGETODAY from "../EXCHANGETODAY/EXCHANGETODAY";
import COMMISION from "../COMMISION/COMMISION";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const INVESTMENTPLANS = () => {
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`https://api.prospectstreetx.com/api/user/investment/plan/view`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setData(data?.data);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/investment/plan/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState("");

  const handlePage = (event) => {
    try {
      if (event?.target?.value) {
        setFilter(event?.target?.value);
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      if (event?.target?.value) {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/investment/plan/filter/view?&page=1&filter=${event?.target?.value}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/investment/plan/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Modalshow, setModalshow] = useState(false);
  const [SingleData, setSingleData] = useState([]);

  const handleModal = (id) => {
    if (id) {
      fetch(`https://api.prospectstreetx.com/api/user/investment/plan/view/${id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
        });
    }
    setModalshow(true);
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  } else {
    return (
      <>
        <InvestModal
          className="posce-trade-modal"
          show={Modalshow}
          singledata={SingleData}
          setmodalshow={setModalshow}
          onHide={() => setModalshow(false)}
        />

        <EXCHANGETODAY></EXCHANGETODAY>

        <section className="investment-plan-section">
          <div className="planwrap">
            <div className="container">
              <div className="investment-plan-title">
                <h4>OUR INVESTMENT PLANS</h4>
              </div>
              <div className="demo">
                <div className="order-filter-area">
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-filter" /> Filter
                  </p>

                  <div className="form-group">
                    <select className="form-select" onChange={handlePage}>
                      <option selected value="createdAt">
                        Newest
                      </option>
                      <option value="-createdAt">Older </option>
                    </select>
                  </div>
                </div>
                <div className="row gy-3">
                  {data?.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <SingleItem
                            data={data}
                            index={index}
                            key={data._id}
                            authUser={authUser}
                            handleModal={handleModal}
                          ></SingleItem>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div className="text-center h-50">
                        <h6> Data not found</h6>
                      </div>
                    </>
                  )}
                </div>
                {/* <div className="row gy-3" style={{ marginTop: "20px" }}>
                <div className="col-sm-12 col-12 col-md-6 col-lg-3 col-xxl-3 col-xl-3">
                </div>
                  <div className="col-sm-12 col-12 col-md-6 col-lg-3 col-xxl-3 col-xl-3">
                    <div
                      className="pricingTable blue"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                    >
                      <div className="pricingTable-header">
                        <h3 className="title">PLAN 5</h3>
                      </div>
                      <div className="price-value">
                        <span>10% DAILY </span>
                      </div>
                      <ul className="pricing-content">
                        <li>80 DAYS</li>
                        <li>$100,000 - $200,000</li>
                        <li>Principal Return</li>
                      </ul>
                      <div className="pricingTable-signup">
                        <Link to="/login">INVEST</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-12 col-md-6 col-lg-3 col-xxl-3 col-xl-3">
                    <div
                      className="pricingTable blue"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                    >
                      <div className="pricingTable-header">
                        <h3 className="title">PLAN 6</h3>
                      </div>
                      <div className="price-value">
                        <span>15% DAILY</span>
                      </div>
                      <ul className="pricing-content">
                        <li>100 DAYS</li>
                        <li>$200,000 - $10,00000</li>
                        <li>Principal Return</li>
                      </ul>
                      <div className="pricingTable-signup">
                        <Link to="/login">INVEST</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-12 col-md-6 col-lg-3 col-xxl-3 col-xl-3">
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <COMMISION></COMMISION>
      </>
    );
  }
};

export default INVESTMENTPLANS;
