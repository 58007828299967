import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dateFormat from "dateformat";
const SingleItem = ({ data, NoticeModalShow }) => {
  return (
    <>
      <div
        className="announcement-page-item"
        onClick={() => NoticeModalShow(data?._id)}
      >
        <LazyLoadImage
          alt="img"
          effect="blur"
          src={`https://api.prospectstreetx.com/${data?.image}`}
        />
        
        <label
          for="announcement-page1"
          className="announcement-page-item-title"
        >
          <p>   <span className="m-0"> {dateFormat(data?.createdAt, "d/m/yyyy")}</span></p>
          {data?.name}
          <span>Click Here</span>
        </label>
      </div>
    </>
  );
};

export default SingleItem;
