import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import MobileLoanSingleHistoryItem from "./MobileLoanSingleHistoryItem";
import LoanHistoryDetails from "../MobileHistoryPage/LoanHistory/LoanHistoryDetails";

const MobileLoanHistory = () => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loanHistory, setLoanHistory] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/loan/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoanHistory(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  // Handle submit form

  const [singleDataId, setSingleDataId] = useState(null);
  const [Modal, setModalhow] = useState(false);

  const HandleModalShow = (id) => {
    setSingleDataId(id);
    setModalhow(true);
  };

  if (loading) {
    return;
  } else {
    return (
      <>
        <div className="container">
          <div className="pb-3">
            <div className="mobile-history-section">
              <div className="mobile-history-title">
                <h4>Latest Loan</h4>
              </div>
              <div className="mobile-history-item-list">
                {loanHistory?.length !== 0 ? (
                  loanHistory.map((data, index) => {
                    if (data) {
                      return (
                        <MobileLoanSingleHistoryItem
                          data={data}
                          index={index}
                          key={data?._id}
                          HandleModalShow={HandleModalShow}
                        ></MobileLoanSingleHistoryItem>
                      );
                    }
                  })
                ) : (
                  <div className="text-center py-5">
                    <p className="text-dark">No records available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {(() => {
          if (Modal) {
            return (
              <LoanHistoryDetails
                singleDataId={singleDataId}
                setShow={setModalhow}
                show={Modal}
              ></LoanHistoryDetails>
            );
          }
        })()}

      </>
    );
  }
};

export default MobileLoanHistory;
