import React from "react";
import MobileSilder from "./MobileSilder/MobileSilder";
import MobileCryptoChain from "./MobileCryptoChain/MobileCryptoChain";
import UserBalance from "./UserBalance/UserBalance";
import MobileDashboardCard from "./MobileDashboardCard/MobileDashboardCard";

const MobileUserDashboard = () => {
  return (
    <>
      <MobileSilder></MobileSilder>
      <UserBalance />
      <MobileDashboardCard />
      <MobileCryptoChain></MobileCryptoChain>
    </>
  );
};

export default MobileUserDashboard;
