import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import DashBoardSideBar from "../UserDashBoard/DashBoardSideBar";
import { Circles } from "react-loader-spinner";
const Identification = () => {
  const { authUser } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.prospectstreetx.com/api/admin/user/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  if (loading) {
    <div className="loader-section d-flex justify-content-center align-items-center desktop-version">
      <Circles
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>;
  } else {
    return (
      <>
        <section className="user-dashboard">
          <div className="row">
            <div className="col-3 user-menu-left">
              <DashBoardSideBar></DashBoardSideBar>
            </div>
            <div className="col-9">
              <section className="container-custom">
                <div className="pt-3">
                  <nav className="breadcrumb-area" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Profile</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Identification
                      </li>
                    </ol>
                  </nav>
                  <div className="user-identification-title">
                    <h2>Identification</h2>
                  </div>
                  <div className="user-KYC">
                    <div className="user-KYC-title">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h3>KYC</h3>
                          <p>
                            Increase your 24-hour withdrawal limit to 200 BTC
                          </p>
                        </div>
                        {userData?.kv ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled
                          >
                            KYC Verified
                          </button>
                        ) : (
                          <Link
                            to="/id-auth/Kyc"
                            className="btn btn-primary"
                          >
                            Verify
                          </Link>
                        )}
                      </div>
                    </div>
                    <p className="user-KYC-dis">Personal information</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Identification;
