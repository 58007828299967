import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { useParams } from "react-router-dom";
import BuyCoinModal from "./Modal/BuyCoinModal";
import SellCoinModal from "./Modal/SellCoinModal";

const MobileNewCoinCandle = () => {
  const { authUser } = useContext(AuthContext);
  const { id } = useParams();

  const [userData, setUserData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id, updateData]);

  const [coinData, setCoinData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/new/coin/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCoinData(data.data);
      });
  }, []);

  const [livePrice, setLivePrice] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice, coinData]);

  const fetchData = async () => {
    if (coinData?.symbol) {
      fetch(
        `https://api.prospectstreetx.com/api/user/new/coin/price/view/${coinData?.symbol}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLivePrice(data.data);
          if (data.data) {
            setLivePrice(data?.data[0]);
          }
        });
    }
  };


//   modal 

const [BuyModalShow, setBuyModalShow] = useState(false);
const [SellModalShow, setSellModalShow] = useState(false);


  return (
    <>
      <section className=" mobile-trade-section user-dashboard">
        <div className="row">
          <div className="col-12">
            <div className="trade-area-posce">
              <div className="charts pb-4">
                <div
                  className="charts__head"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                >
                  <div className="d-flex gap-3">
                    <button
                      className="nav__link js-tabs-link active"
                      style={{ color: "lime" }}
                    >
                      $
                      {!isNaN(userData?.balance) &&
                        parseFloat(userData?.balance).toFixed(2)}
                    </button>
                  </div>

                  {livePrice?.close && (
                    <div className="nav">
                      <button
                        className="nav__link js-tabs-link active"
                        style={{ color: "lime" }}
                      >
                        {livePrice?.close}
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="trade-charts-area-posce">
                  <MobileTradingViewWidget
                    symbol={symbol}
                  ></MobileTradingViewWidget>
                </div> */}
              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-success  w-100 "
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={()=>setBuyModalShow(true)}
                >
                  Buy
                </button>

                <button
                  type="button"
                  className="btn btn-danger  w-100"
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={()=>setSellModalShow(true)}
                >
                  Sell
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>


      <BuyCoinModal
          className="mobile-trade-modal-section posce-trade-modal"
          BuyModalShow={BuyModalShow}
          setBuyModalShow={setBuyModalShow}
          livePrice={livePrice}
          coinData={coinData}
          show={BuyModalShow}
          userData={userData}
          setUpdateData={setUpdateData}
          onHide={() => setBuyModalShow(false)}
        />
      <SellCoinModal
          className="mobile-trade-modal-section posce-trade-modal"
          SellModalShow={SellModalShow}
          setSellModalShow={setSellModalShow}
          livePrice={livePrice}
          coinData={coinData}
          show={SellModalShow}
          userData={userData}
          setUpdateData={setUpdateData}
          onHide={() => setSellModalShow(false)}
        />
   
    </>
  );
};

export default MobileNewCoinCandle;
