import React, { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import DashBoardSideBar from "../UserDashBoard/DashBoardSideBar";
import { useNavigate } from "react-router-dom";

const PasswordChange = () => {
  const { LoginWithEmail, authUser } = useContext(AuthContext);
  const [updateData, setUpdateData] = useState({});
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.prospectstreetx.com/api/admin/user/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser, updateData]);


//   Login 

const from = "/login";
const navigate = useNavigate();
const [remove, setremove] = useState(false);
function getCookie(name) {
  const cookieValue = document.cookie.match(
    `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
  );
  return cookieValue ? cookieValue.pop() : "";
}

let token = getCookie("gffex_token");
const SignOut = () => {
  document.cookie = `gffex_token=; expires=${new Date(
    0
  ).toUTCString()}; path=/`;
  localStorage.removeItem("ID");
  const data = null;
  LoginWithEmail(data);
  if (authUser === null) {
    navigate(from, { replace: true });
  }
  setremove(true);
};
const logout = localStorage.getItem("ID");

useEffect(() => {
  if (!logout && remove === true) {
    navigate(from, { replace: true });
  }
}, [remove, logout, navigate]);

  // store data

  const [dataValue, setDataValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    if (userData?._id) {
      axios
        .put(
          `https://api.prospectstreetx.com/api/user/auth/password/update/${userData?._id}`,
          dataValue,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          if(data.data.success){
            SignOut();
          }
          setUpdateData(data.data);
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          if (error?.response?.data?.success === false) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
          }
        });
    } else {
      toast.error(`something is worng`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>

      <section className="user-dashboard">
        <div className="row">
          <div className="col-3 user-menu-left">
            <DashBoardSideBar></DashBoardSideBar>
          </div>
          <div className="col-9">
            <div className="user-profile-area">
              <div className="container my-4">
                <div className="row gutters">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                    <div className="card h-100">
                      <div className="card-body">
                        <form onSubmit={handleSubmitData}>
                          <div className="row gutters">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <h6 className="mb-3 text-primary">Password Update</h6>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label for="fullName">Old Password</label>
                                <input type="password" onBlur={handleInputBlur} name='password' className="form-control" id="exampleFormControlInput2" aria-describedby="emailHelp" placeholder="old password" required />
                              </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label for="lastname">New Password</label>
                                <input type="password" onBlur={handleInputBlur} name='npassword' className="form-control" id="exampleFormControlInput2" aria-describedby="emailHelp"  placeholder="new password" required />
                              </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label for="phone">Confirm Password</label>
                                <input type="password" onBlur={handleInputBlur} name='cpassword' className="form-control" id="exampleFormControlInput2" aria-describedby="emailHelp"  placeholder="confirm password" required />
                              </div>
                            </div>
                          </div>
                         
                          <div className="row gutters">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="text-right">
                                <button
                                  type="submit"
                                  id="submit"
                                  className="btn btn-primary px-5 fw-normal d-block mt-3"
                                  ref={refSubmitDis}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordChange;
