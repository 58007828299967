import React, { useEffect, useState } from "react";

const SingleItem = ({
  data,
  handleSubmitData,
  refSubmitDis,
  errorMessage,
}) => {

  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(data?.loan_id){
      fetch(`https://api.prospectstreetx.com/api/user/loan/package/view/${data?.loan_id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [data?.loan_id]);

  if(loading){
    return;
  }

  // {data?.}
  if (singleData?.template === 1) {
    return (
      <>
        <div className="col-sm-4 loan-card loan-card-tem-one">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <h2>{singleData?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {singleData?.percentage}%
                <span className="text-small">
                  &nbsp;/ {singleData?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {singleData?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {singleData?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {singleData?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {singleData?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
            <input type="hidden" name="id" value={singleData?._id} />
              <div className="modal-body">
                <div className="form-group">
                  <label for="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.id && errorMessage?.id === singleData?._id && (
                    <>
                      {errorMessage?.errors?.amount && (
                        <span className="text-dark pt-2">
                          {errorMessage?.errors?.amount?.msg}
                        </span>
                      )}

                      {errorMessage?.message && (
                        <span className="text-dark pt-2">
                          {errorMessage?.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  if (singleData?.template === 2) {
    return (
      <>
        <div className="col-sm-4 loan-card loan-card-tem-two">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <h2>{singleData?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {singleData?.percentage}%
                <span className="text-small">
                  &nbsp;/ {singleData?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {singleData?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {singleData?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {singleData?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {singleData?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
            <input type="hidden" name="id" value={singleData?._id} />
              <div className="modal-body">
                <div className="form-group">
                  <label for="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.id && errorMessage?.id === singleData?._id && (
                    <>
                      {errorMessage?.errors?.amount && (
                        <span className="text-dark pt-2">
                          {errorMessage?.errors?.amount?.msg}
                        </span>
                      )}

                      {errorMessage?.message && (
                        <span className="text-dark pt-2">
                          {errorMessage?.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  if (singleData?.template === 3) {
    return (
      <>
        <div className="col-sm-4 loan-card loan-card-tem-three">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <h2>{singleData?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {singleData?.percentage}%
                <span className="text-small">
                  &nbsp;/ {singleData?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {singleData?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {singleData?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {singleData?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {singleData?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
            <input type="hidden" name="id" value={singleData?._id} />
              <div className="modal-body">
                <div className="form-group">
                  <label for="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.id && errorMessage?.id === singleData?._id && (
                    <>
                      {errorMessage?.errors?.amount && (
                        <span className="text-dark pt-2">
                          {errorMessage?.errors?.amount?.msg}
                        </span>
                      )}

                      {errorMessage?.message && (
                        <span className="text-dark pt-2">
                          {errorMessage?.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};

export default SingleItem;
