import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import MobileHomeRoute from '../../Route/MobileHomeRoute';

const HomeMain = () => {
    return (
        <>
         
            <MobileHomeRoute> 
            <Header></Header>
            <>
                <Outlet></Outlet>
                <Footer></Footer>
            </>

            </MobileHomeRoute> 
            
        </>

    );
};

export default HomeMain;