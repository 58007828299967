import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const DashBoardSideBar = () => {
    return (
        <>
           <div className="user-menu-area pt-3">
                            <NavLink to='/dashboard' className="user-nav-items ">
                            <FontAwesomeIcon icon="fa-regular fa-user" />
                            <p>Dashboard</p>
                            </NavLink>   
                            <NavLink to='/trade' className="user-nav-items" end>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill-trend-up" />
                            <p>Trade</p>
                            </NavLink>                    
                            <NavLink to='/trade/history' className="user-nav-items" end >
                            <FontAwesomeIcon icon="fa-solid fa-shield" />
                            <p> Trade History</p>
                            </NavLink>  
                            <NavLink to='/bitcoin/mining' className="user-nav-items" end>
                            <FontAwesomeIcon icon="fa-solid fa-shield" />
                            <p>BitCoin Mining</p>
                            </NavLink>  
                            <NavLink to='/gold/coin/mining' className="user-nav-items" end>
                            <FontAwesomeIcon icon="fa-solid fa-shield" />
                            <p>Gold Mining</p>
                            </NavLink>  
                            <NavLink to='/assets/deposit' className="user-nav-items">
                            <FontAwesomeIcon icon="fa-solid fa-receipt" />
                            <p>Deposit</p>
                            </NavLink>    
                            <NavLink to='/assets/withdraw' className="user-nav-items">
                            <FontAwesomeIcon icon="fa-solid fa-square-check" />
                            <p>Withdrawal <br /> Addresses/Contacts</p>
                            </NavLink>                       
                            <NavLink to='/invest/plan/history' className="user-nav-items">
                            <FontAwesomeIcon icon="fa-solid fa-square-check" />
                            <p>Invest Plan History</p>
                            </NavLink>                       
                            <NavLink to='/apply/loan' className="user-nav-items" end>
                            <FontAwesomeIcon icon="fa-solid fa-square-check" />
                            <p>Apply  Loan</p>
                            </NavLink>        
                            <NavLink to='/#' className="user-nav-items" end>
                            <FontAwesomeIcon icon="fa-solid fa-square-check" />
                            <p>Purchase New Coin</p>
                            </NavLink>                       
                            <NavLink to='/assets/record' className="user-nav-items">
                            <FontAwesomeIcon icon="fa-brands fa-quinscape" />
                            <p>Transaction Log</p>
                            </NavLink>                       
                                                
                         
                                                
                        </div> 
        </>
    );
};

export default DashBoardSideBar;