import React, { useEffect, useState } from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
const SingleItem = ({
  data,
  handleMiningInputBlur,
  handleSubmitData,
  setFindMiningId,
  refSubmitDis,
}) => {
  const [Miningdata, setMiningdata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.mining_id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/mining/view/${data?.mining_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMiningdata(data?.data);
          setLoading(false);
        });
    }
  }, [data?.mining_id]);

  if (loading) {
    return;
  }
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="card-custom">
          <div className="badge-custom2"></div>
          <div className="badge-custom">{Miningdata?.percentage}%</div>
          <h5 className="mt-3">
            {Miningdata?.instant === 1 ? (
              <>
                <b>
                  <span style={{ color: "gold" }}>Daily for&nbsp;</span>
                </b>
                <span>
                  {`${Miningdata?.period}`} {`${Miningdata?.period_type}`}
                </span>
              </>
            ) : (
              <>
                <b>
                  <span style={{ color: "gold" }}>AFTER for&nbsp;</span>
                </b>
                <span>
                  {`${Miningdata?.period}`} {`${Miningdata?.period_type}`}
                </span>
              </>
            )}
          </h5>
          <div className="d-flex flex-row-reverse">
            <div className="text-box"> {Miningdata?.promotion_name} </div>
          </div>
          <div className="investment-info">
            <p>Min Deposit: ${Miningdata?.min_amount}</p>
            <p>Max Deposit: ${Miningdata?.max_amount}</p>
            <p>
              Principal:
              {Miningdata?.principal === 1 ? (
                <span> Returns</span>
              ) : Miningdata?.principal === 2 ? (
                <span> Included</span>
              ) : (
                ""
              )}
            </p>
          </div>
          <p className="btn btn-primary expired_time-css">
            <FlipClockCountdown
              to={Miningdata?.expired_time}
              className="flip-clock"
              labels={["", "", "", ""]}
            ></FlipClockCountdown>
          </p>
          <form onSubmit={handleSubmitData}>
            <label className="mt-2">Amount</label>
            <input
              name="amount"
              className="form-control"
              placeholder="Enter amount "
              required=""
              id="amount"
              onBlur={handleMiningInputBlur}
            />
            <div className="pt-3">
              <button
                type="submit"
                onClick={() => setFindMiningId(Miningdata?._id)}
                ref={refSubmitDis}
                className="but1 hvr-shutter-in-horizontal"
              >
                Subscribe Here <i className="ri-play-fill"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
