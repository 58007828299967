import React from "react";

const MobileGift = () => {
  return (
    <>
      <section className="mobile-contact-section">
        <div className="container">
          <div className="card mb-3">
            <div className="card-content">
              <h5>upcoming</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileGift;
