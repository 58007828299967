import React, { useContext,  useEffect,  useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import DashBoardSideBar from "../../UserDashBoard/DashBoardSideBar";
import TradingViewWidget from "../../TradingViewWidget/TradingViewWidget";
import LongModal from "./Modal/LongModal/LongModal";
import ShortModal from "./Modal/ShortModal/ShortModal";
const EnergisesTrade = () => {
    const { id, symbol } = useParams();
    const { authUser } = useContext(AuthContext);
  
    const [longmodalshow, setlongmodalshow] = useState(false);
    const [shortModalShow, setShortModalShow] = useState(false);
  
    const [marketData, setMarketData] = useState([]);
  
    useEffect(() => {
      if(id){
        fetch(`https://api.prospectstreetx.com/api/user/trade/market/energises/view/${id}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            setMarketData(data?.data);
          });
      }
    }, [id]);
   
  return (
    <>
      <section className="user-dashboard">
        <div className="row">
          <div className="col-3 user-menu-left">
            <DashBoardSideBar></DashBoardSideBar>
          </div>
          <div className="col-9">
            <div className="trade-area-posce">
              <div className="charts">
                <div
                  className="charts__head"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                >
                  <div className="d-flex gap-3">
                    <div className="nav">
                      <img
                        src="https://cryptolly.netlify.app/assets/media/images/icons/menu.svg"
                        alt="MENU"
                      />
                    </div>
                    <div className="charts__group">
                      <div className="nice-select select" tabIndex="0">
                        <span className="current">Time</span>
                        <ul className="list">
                          <li data-value="Time" className="option selected">
                            Time
                          </li>
                          <li data-value="1H" className="option">
                            1H
                          </li>
                          <li data-value="4H" className="option">
                            4H
                          </li>
                          <li data-value="1D" className="option">
                            1D
                          </li>
                          <li data-value="1W" className="option">
                            1W
                          </li>
                          <li data-value="1M" className="option">
                            1M
                          </li>
                        </ul>
                      </div>
                      <div className="nav">
                        <button className="nav__link">Time</button>
                        <button className="nav__link active">1H</button>
                        <button className="nav__link">4H</button>
                        <button className="nav__link">1D</button>
                        <button className="nav__link">1W</button>
                        <button className="nav__link">1M</button>
                      </div>
                    </div>
                  </div>
                  <div className="nav">
                    <button
                      className="nav__link js-tabs-link active"
                      style={{ color: "lime" }}
                    >
                      ${!isNaN(authUser?.balance) && parseFloat(authUser?.balance).toFixed(2)}
                    </button>
                  </div>
                </div>
              </div>
  
              <div className="trade-charts-area-posce">
                <TradingViewWidget symbol={symbol}></TradingViewWidget>
              </div>
              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-success  w-100 "
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={() => setlongmodalshow(true)}
                >
                  Long
                </button>
  
                <button
                  type="button"
                  className="btn btn-danger  w-100"
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={() => setShortModalShow(true)}
                >
                  Short
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <LongModal
        className="posce-trade-modal"
        marketData={marketData}
        setlongmodalshow={setlongmodalshow}
        longmodalshow={longmodalshow}
        symbol={symbol}
        id={id}
        show={longmodalshow}
        onHide={() => setlongmodalshow(false)}
      />
      <ShortModal
        className="posce-trade-modal"
        marketData={marketData}
        setShortModalShow={setShortModalShow}
        shortModalShow={shortModalShow}
        symbol={symbol}
        id={id}
        show={shortModalShow}
        onHide={() => setShortModalShow(false)}
      />
  
  
    </>
  );
  };
  
  
export default EnergisesTrade;