import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Crypto = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.prospectstreetx.com/api/user/trade/market//crypto/currency/mobile/filter/view`,
      {
        method: "GET",
      } 
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);


  if(loading){
    return;
  }

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-top"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div className="d-flex gap-2 flex-column my-3" id="coinContainer">
            {data.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
              ></SingleItem>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Crypto;
