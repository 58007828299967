import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import dateFormat from "dateformat";
const TradeHistoryDetails = ({ setShow, singleDataId, show }) => {
  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShow(false);
    setSingleData([]);
    setLoading(false);
  };
  useEffect(() => {
    if (singleDataId) {
      fetch(
        `https://api.prospectstreetx.com/api/user/trade/log/single/view/${singleDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [singleDataId]);

  function ModalComponent(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="announcement-modal-show notices-modal-show "
        backdrop="static"
      >
        <div className="announcement-header">
          <h4>Details</h4>
          <button onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <Modal.Body>
          <div className="history-details-modal-area">
            <div className="container">
              <div className="history-details-modal-item">
                <p>Crypto&nbsp;Currency</p>
                <span>{singleData?.Crypto}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Amount</p>
                <span>${singleData?.Amount}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Profit</p>
                <span>${singleData?.Result_Amount}</span>
              </div>
              <div className="history-details-modal-item">
                <p>High/Low</p>
                {singleData?.HighLow === "High" ? (
                  <span className="badge bg-success">High</span>
                ) : (
                  <span className="badge bg-danger">Low</span>
                )}
              </div>
              <div className="history-details-modal-item">
                <p>Result</p>
                {singleData?.Result === null && (
                  <span className="badge bg-warning ">Pending</span>
                )}
                {singleData?.Result === "Win" && (
                  <span className="badge bg-success">Win</span>
                )}
                {singleData?.Result === "Loss" && (
                  <span className="badge bg-danger">Loss</span>
                )}
                {singleData?.Result === "Draw" && (
                  <span className="badge bg-dark">Draw</span>
                )}
              </div>
              <div className="history-details-modal-item">
                <p>Status</p>
                {singleData?.Result === null ? (
                  <span className="badge bg-warning ">Running</span>
                ) : (
                  <span className="badge bg-success">Completed</span>
                )}
              </div>
              <div className="history-details-modal-item">
                <p>Date</p>
                <span>
                  {dateFormat(singleData?.createdAt, "mmm,")}&nbsp;
                  {dateFormat(singleData?.createdAt, "dS")}&nbsp;
                  {dateFormat(singleData?.createdAt, "yyyy")}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center"></Modal.Footer>
      </Modal>
    );
  }
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <ModalComponent show={show} onHide={() => setShow(false)} />
      </>
    );
  }
};

export default TradeHistoryDetails;
