import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../Loader/Loader";
import InvestModal from "../../../Page/InvestPage/InvestModal/InvestModal";
import MobileInvestPlanHistory from "./MobileInvestPlanHistory/MobileInvestPlanHistory";
import CustomPaginationMobile from "../../../CustomPagination/CustomPaginationMobile";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MobileInvestPlan = () => {
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`https://api.prospectstreetx.com/api/user/investment/plan/view`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setData(data?.data);
  //       setPaginateData(data);
  //       setLoading(false);
  //     });
  // }, []);

  // const handlePage = (event) => {
  //   try {
  //     const pageNumber = event?.selected + 1;
  //     const config = {
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     };
  //     axios
  //       .get(
  //         `https://api.prospectstreetx.com/api/user/investment/plan/view?page=${pageNumber}`,
  //         config
  //       )
  //       .then((response) => {
  //         setData(response?.data?.data);
  //         setPaginateData(response?.data);
  //       })
  //       .catch((error) => {});
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/investment/plan/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState("");

  const handlePage = (event) => {
    try {
      if (event?.target?.value) {
        setFilter(event?.target?.value);
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      if (event?.target?.value) {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/investment/plan/filter/view?&page=1&filter=${event?.target?.value}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/investment/plan/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Modalshow, setModalshow] = useState(false);
  const [SingleData, setSingleData] = useState([]);

  const handleModal = (id) => {
    if (id) {
      fetch(`https://api.prospectstreetx.com/api/user/investment/plan/view/${id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
        });
    }
    setModalshow(true);
  };

  // calcultor

  const [calData, setCalData] = useState([]);
  const [DailyProfit, setDailyProfit] = useState(0.0);
  const [TotalProfit, setTotalProfit] = useState(0.0);
  const [dataValue, setDataValue] = useState({});
  const handleSelect = (e) => {
    if (e.target.value) {
      fetch(
        `https://api.prospectstreetx.com/api/user/investment/plan/view/${e.target.value}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (dataValue?.amount) {
            if (
              parseFloat(dataValue?.amount) < parseFloat(data?.data?.min_amount)
            ) {
              setDailyProfit(`min:$${data?.data?.min_amount}`);
              setTotalProfit(`min:$${data?.data?.min_amount}`);
              return;
            } else if (
              parseFloat(dataValue?.amount) > parseFloat(data?.data?.max_amount)
            ) {
              setDailyProfit(`max:$${data?.data?.max_amount}`);
              setTotalProfit(`max:$${data?.data?.max_amount}`);
              return;
            }
            if (data?.data?.period_type === "Day") {
              if (data?.data?.principal === 1) {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum =
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100
                  ) / parseFloat(data?.data?.period);
                const TotalProfitsum =
                  parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                  parseFloat(dataValue?.amount);
                setDailyProfit(Dailysum);
                setTotalProfit(TotalProfitsum);
              } else {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum =
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100 -
                      parseFloat(dataValue?.amount)
                  ) / parseFloat(data?.data?.period);
                const TotalProfitsum =
                  parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                  parseFloat(dataValue?.amount);
                setDailyProfit(Dailysum.toFixed(2));
                setTotalProfit(TotalProfitsum.toFixed(2));
              }
            } else if (data?.data?.period_type === "Months") {
              if (data?.data?.principal === 1) {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum = parseFloat(
                  parseFloat(
                    parseFloat(
                      (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                        100
                    ) / parseFloat(parseFloat(data?.data?.period) * 30)
                  )
                );
                const TotalProfitsum = parseFloat(
                  parseFloat(Dailysum) *
                    parseFloat(parseFloat(data?.data?.period) * 30) +
                    parseFloat(dataValue?.amount)
                );
                setDailyProfit(Dailysum);
                setTotalProfit(TotalProfitsum);
              } else {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum = parseFloat(
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100 -
                      parseFloat(dataValue?.amount)
                  ) / parseFloat(parseFloat(data?.data?.period) * 30)
                );
                const TotalProfitsum = parseFloat(
                  parseFloat(Dailysum) *
                    parseFloat(parseFloat(data?.data?.period) * 30) +
                    parseFloat(dataValue?.amount)
                );
                setDailyProfit(Dailysum.toFixed(2));
                setTotalProfit(TotalProfitsum.toFixed(2));
              }
            }

            setCalData(data?.data);
          } else {
            setCalData(data?.data);
          }
        });
    }
  };

  const handleInputBlur = (event) => {
    if (calData?._id) {
      const value = event.target.value;
      const field = event.target.name;
      if (parseFloat(value) < parseFloat(calData?.min_amount)) {
        setDailyProfit(`min:$${calData?.min_amount}`);
        setTotalProfit(`min:$${calData?.min_amount}`);
      } else if (parseFloat(value) > parseFloat(calData?.max_amount)) {
        setDailyProfit(`max:$${calData?.max_amount}`);
        setTotalProfit(`max:$${calData?.max_amount}`);
      } else if (calData?.period_type === "Day") {
        if (calData?.principal === 1) {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat((parseFloat(value) * parseFloat(percentage)) / 100) /
            parseFloat(calData?.period);
          const TotalProfitsum =
            parseFloat(Dailysum) * parseFloat(calData?.period) +
            parseFloat(value);
          setDailyProfit(Dailysum);
          setTotalProfit(TotalProfitsum);
        } else {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat(
              (parseFloat(value) * parseFloat(percentage)) / 100 -
                parseFloat(value)
            ) / parseFloat(calData?.period);
          const TotalProfitsum =
            parseFloat(Dailysum) * parseFloat(calData?.period) +
            parseFloat(value);
          setDailyProfit(Dailysum.toFixed(2));
          setTotalProfit(TotalProfitsum.toFixed(2));
        }
      } else if (calData?.period_type === "Months") {
        if (calData?.principal === 1) {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat((parseFloat(value) * parseFloat(percentage)) / 100) /
            parseFloat(parseFloat(calData?.period) * 30);
          const TotalProfitsum =
            parseFloat(Dailysum) *
              parseFloat(parseFloat(calData?.period) * 30) +
            parseFloat(value);
          setDailyProfit(Dailysum);
          setTotalProfit(TotalProfitsum);
        } else {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat(
              (parseFloat(value) * parseFloat(percentage)) / 100 -
                parseFloat(value)
            ) / parseFloat(parseFloat(calData?.period) * 30);
          const TotalProfitsum =
            parseFloat(Dailysum) *
              parseFloat(parseFloat(calData?.period) * 30) +
            parseFloat(value);
          setDailyProfit(Dailysum.toFixed(2));
          setTotalProfit(TotalProfitsum.toFixed(2));
        }
      }
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    } else {
      const value = event.target.value;
      const field = event.target.name;
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    }
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  } else {
    return (
      <>
        <InvestModal
          className="posce-trade-modal"
          show={Modalshow}
          setmodalshow={setModalshow}
          singledata={SingleData}
          onHide={() => setModalshow(false)}
        />

        <section className="investment-plan-section mobile-user-investment-plan-section">
          <div className="planwrap">
            <div className="container">
              <div className="demo">
                <div className="order-filter-area">
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-filter" /> Filter
                  </p>

                  <div className="form-group">
                    <select className="form-select" onChange={handlePage}>
                      <option selected value="createdAt">
                        Newest
                      </option>
                      <option value="-createdAt">Older </option>
                    </select>
                  </div>
                </div>
                <div className="row gy-3">
                  {data?.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <SingleItem
                            data={data}
                            index={index}
                            key={data._id}
                            authUser={authUser}
                            handleModal={handleModal}
                          ></SingleItem>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div className="text-center h-50">
                        <h6> Data not found</h6>
                      </div>
                    </>
                  )}
                </div>
                {/* paginateLinks */}
                <CustomPaginationMobile
                  data={paginateData}
                  handlePage={handlePage}
                ></CustomPaginationMobile>
                {/* paginateLinks */}

                <div className="row">
                  <div className="calci_bg">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <ul className="calci">
                            <li>
                              <div className="cal_text">
                                <h3>
                                  Select Your Plan
                                  <select
                                    value={calData?._id}
                                    onChange={handleSelect}
                                    id="Ultra"
                                  >
                                    <option selected>Select Now</option>
                                    {data.map((data, idx) => {
                                      return (
                                        <option
                                          key={data?._id}
                                          value={data?._id}
                                        >
                                          {data.promotion_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </h3>
                              </div>
                            </li>
                            <li>
                              <div className="cal_text">
                                <h3>
                                  Enter Your Amount
                                  <input
                                    type="number"
                                    className="inpts"
                                    name="amount"
                                    onChange={handleInputBlur}
                                    id="money"
                                  />
                                </h3>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-lg-4">
                          <ul className="calci1">
                            <li>
                              <div className="cal_text">
                                <h3>
                                  Daily Profit
                                  <span id="profitDaily">
                                    {typeof DailyProfit !== "string"
                                      ? parseFloat(DailyProfit).toFixed(2)
                                      : DailyProfit}
                                  </span>
                                </h3>
                              </div>
                            </li>
                            <li>
                              <div className="cal_text">
                                <h3>
                                  Total Profit
                                  <span className="cl1" id="profitTotal">
                                    {TotalProfit}
                                  </span>
                                </h3>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-lg-4">
                          <div className="text_but text-center">
                            <button
                              type="submit"
                              className="but1 hvr-shutter-in-horizontal"
                            >
                              Calculate Now
                              <i className="ri-play-fill"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MobileInvestPlanHistory />
        </section>
      </>
    );
  }
};
export default MobileInvestPlan;
