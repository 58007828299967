import axios from "axios";
import React, {  useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const SellCoinModal = (props) => {
  const { livePrice, coinData, userData, setSellModalShow, setUpdateData } = props;

  const refSubmit = useRef(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmit.current = true;
    setErrorMessage("");
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const storeData = {
      ...InpuData,
      coin: coinData,
      user: userData,
      coin_price: livePrice?.close,
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(`https://api.prospectstreetx.com/api/user/new/coin/sell`, storeData, config)
      .then((response) => {
        event.target.reset();
        toast.success(`${response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refSubmit.current = false;
        setSellModalShow(false);
        setUpdateData(response?.data);
      })
      .catch((error) => {
        refSubmit.current = false;
        if (error?.response?.data?.errors) {
          setErrorMessage(error?.response?.data?.errors);
        } else {
          setErrorMessage(error?.response?.data);
        }
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 class="modal-title">Sell Coin</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Name:</strong> {coinData?.name && coinData?.name}
          </p>
          <p>
            <strong>Coin Symbol:</strong>
            <span> {coinData?.symbol}</span>
          </p>
          <p>
            <strong>Current price:</strong> {livePrice?.close}
          </p>
          <br />
          <p className="mb-1">
            <strong>Coin</strong>
          </p>
          <form onSubmit={handleSubmitData}>
            <div class="bargaining__fieldset pb-2">
              <input
                class="field__input bargaining__field mb-0"
                type="text"
                name="coin_amount"
                placeholder="coin"
                required=""
                id="coin_amount"
              />
              {errorMessage?.coin_amount && (
                <span className="text-danger ">
                  {errorMessage?.coin_amount?.msg}
                </span>
              )}
              {errorMessage?.message && (
                <span className="text-danger ">
                  {errorMessage?.message}
                </span>
              )}
            </div>

            <p>
              <strong>Available Balance: </strong>
              {!isNaN(userData?.balance) &&
                parseFloat(userData?.balance).toFixed(2)}
              $
            </p>

            <button
              className="btn btn-danger  w-100"
              data-bs-toggle="modal"
              style={{ borderRadius: "30px" }}
              disabled={refSubmit?.current}
            >
              Sell
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SellCoinModal;
