import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import dateFormat from "dateformat";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loader from "../../../Loader/Loader";
import MobileUserFooter from "../../Layouts/MobileUserFooter";

const AnnouncementDetails = () => {
    const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/frontend/home/notices/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  } else {
    return (
      <>
      <section className="mobile-home-main">
        <div className="container">
        <div className="mobile-news-details-section news-details-area">
          <div className="news-details">
            <div className="news-details-title text-center">
              <Link to="/user/announcement">View all Announcement</Link>
            </div>

            <main>
              <div className="news-details-main">
                <LazyLoadImage
                  alt="img"
                  effect="blur"
                  width="32"
                  height="32"
                  className=""
                  src={`https://api.prospectstreetx.com/${data?.image}`}
                />
                <div className="news-title">
                  <h5>{data?.name}</h5>
                </div>
                <div className="news-details-time">
                  <span>{dateFormat(data.createdAt, "d/m/yyyy")}</span>
                </div>
                <div className="news-detail-all">
                  <p className="mb-2 news-detail-short_dis">{data?.short_dis}</p>
                  {data?.dis ? HTMLReactParser(data?.dis) : ""}
                </div>
              </div>
            </main>

          </div>
        </div>
        </div>
     
        <MobileUserFooter></MobileUserFooter>
      </section>
      
      </>
    );
  }
};


export default AnnouncementDetails;