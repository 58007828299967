import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';

const MobileStocks = () => {
    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(`https://api.prospectstreetx.com/api/user/trade/market/stocks/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
            });


    }, [])

    

    const StocksSymbolString = data.reduce((acc, curr) => `${acc}${curr.symbol},`, "");
    const StocksSymbol = StocksSymbolString.substring(0, StocksSymbolString?.length - 1);
  
    const [livePrice, setLivePrice] = useState([]);
  
    useEffect(() => {
        StocksfetchData();
    }, [data]);
  
    const StocksfetchData = async () => {
      if (StocksSymbol?.length !== 0) {
        const data = await fetch(
          `https://marketdata.tradermade.com/api/v1/live?currency=${StocksSymbol}&api_key=E973yln-XwbcM9nvff0R`
        );
        const apiResponse = await data.json();
        setLivePrice(apiResponse?.quotes);
      }
    };

  return (
    <>
      <section className="mobile-user-trade-section">
        <div className="d-lg-none">
          <div className="bg-all mobile-view d-block d-lg-none">
            <div className="tab-content">
            <div id="a1" className="containerTab">
        <div
          className="d-flex pb-2 coin_style_div gap-2 flex-column my-3"
          id="coinContainer"
        >
          <h6
            style={{ backgroundColor: "orange" }}
            className="text-center coin_style_h6 py-2"
          >
            Stock
          </h6>
        </div>
      </div>

      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
            {data.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
                livePrice={livePrice}
              ></SingleItem>
            ))}
          </ul>
        </div>
      </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileStocks;
