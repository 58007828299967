import React from "react";
import { Link } from "react-router-dom";
import "./MobilePlatformApps.css"

const MobilePlatformApps = () => {
  return (
    <>
      <section className="mobile-py-20-120 user-dashboard">
        <div className="user-profile-area">
          <div className="container mb-4">
            <div className="row gutters">
              <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="PlatformApps-btn-area">
                    <div className="PlatformApps-btn">
                      <Link className="menu-item" to="#">
                        <span
                          style={{
                            padding: "4px",
                            backgroundColor: "indigo",
                            borderRadius: "4px",
                            color: "#FFF",
                          }}
                        >
                          <i className="fas text-white  fa fa-download"></i>
                        </span>
                        App Download-Android
                      </Link>
                    </div>
                    <div className="PlatformApps-btn">
                      <Link className="menu-item" to="#">
                        <span
                          style={{
                            padding: "4px",
                            backgroundColor: "black",
                            borderRadius: "4px",
                            color: "#FFF",
                          }}
                        >
                          <i className="fas text-white  fa fa-download"></i>
                        </span>
                        App Download-iOS
                      </Link>
                    </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobilePlatformApps;
