import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import SingleItem from "./SingleItem";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import DashBoardSideBar from "../UserDashBoard/DashBoardSideBar";

const Withdraw = () => {
  const { authUser } = useContext(AuthContext);

  const [dataValue, setDataValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    if (dataValue?.NetworkType) {
      const storeData = { user_id: authUser?._id, ...dataValue };
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .post(
          `https://api.prospectstreetx.com/api/user/withdrawal/confirm`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          if (error?.response?.data?.success === false) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
          }
        });
    } else {
      toast.error(`Network field is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current.removeAttribute("disabled");
    }
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/withdrawal/view/${authUser?._id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.prospectstreetx.com/api/user/withdrawal/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <>
        <div className="loader-section d-flex justify-content-center align-items-center">
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="user-dashboard">
          <div className="row">
            <div className="col-3 user-menu-left">
              <DashBoardSideBar></DashBoardSideBar>
            </div>
            <div className="col-9">
              <div className="col-6">
                <div className="user-profile-area">
                  <div className="container my-4">
                    <div className="row gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card h-100">
                          <div className="card-body">
                            <form onSubmit={handleSubmitData}>
                              <div className="row gutters">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <h6 className="mb-3 text-primary">Withdrawal</h6>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Network
                                    </label>
                                    <select
                                      name="NetworkType"
                                      onBlur={handleInputBlur}
                                      className="form-select"
                                      required
                                    >
                                      <option selected disabled>
                                        select
                                      </option>
                                      <option value="BTC">BTC</option>
                                      <option value="ETH">ETH</option>
                                      <option value="USDT">USDT</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="exampleFormControlInput2"
                                      className="form-label"
                                    >
                                      Amount
                                    </label>
                                    <input
                                      type="number"
                                      name="Amount"
                                      onBlur={handleInputBlur}
                                      className="form-control"
                                      id="exampleFormControlInput2"
                                      aria-describedby="emailHelp"
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="exampleFormControlInput2"
                                      className="form-label"
                                    >
                                      Withdrawal Address
                                    </label>
                                    <input
                                      type="text"
                                      name="WithdrawalAddress"
                                      onBlur={handleInputBlur}
                                      className="form-control"
                                      id="exampleFormControlInput2"
                                      aria-describedby="emailHelp"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row gutters">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div className="text-right">
                                    <button
                                      type="submit"
                                      id="submit"
                                      className="btn btn-primary px-5 fw-normal d-block mt-3"
                                      ref={refSubmitDis}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* deposit history */}

        <section className="container-custom">
          <div className="pb-3">
            <div className="user-deposit-history my-5">
              <div className="user-referral-history-titile">
                <h3>Withdrawal History</h3>
              </div>
              <div className="user-referral-history-items">
                <table className="user-referral-history-table">
                  <thead>
                    <tr className="table-headers">
                      <th>Network Type | Transaction</th>
                      <th>Initiated</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length !== 0 ? (
                      data.map((data, index) => {
                        if (data) {
                          return (
                            <SingleItem
                              data={data}
                              key={data?._id}
                              paginateData={paginateData}
                            ></SingleItem>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td className="text-muted text-center" colSpan="100%">
                          No records available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* paginateLinks */}
              <CustomPagination
                data={paginateData}
                handlePage={handlePage}
              ></CustomPagination>
              {/* paginateLinks */}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Withdraw;
