import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import dateFormat from "dateformat";
const LoanHistoryDetails = ({ setShow, singleDataId, show }) => {
  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShow(false);
    setSingleData([]);
    setLoading(false);
  };
  useEffect(() => {
    if (singleDataId) {
      fetch(
        `https://api.prospectstreetx.com/api/user/loan/history/single/view/${singleDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [singleDataId]);

  function ModalComponent(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="announcement-modal-show notices-modal-show "
        backdrop="static"
      >
        <div className="announcement-header">
          <h4>Details</h4>
          <button onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <Modal.Body>
          <div className="history-details-modal-area">
            <div className="container">
              <div className="history-details-modal-item">
                <p>Plan</p>
                <span>{singleData?.loan_package_name}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Amount</p>
                <span>${singleData?.amount}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Installment&nbsp;Amount</p>
                <span>${singleData?.total_amount}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Installment</p>
                <span >1</span>
              </div>
              <div className="history-details-modal-item">
                <p>Next&nbsp;Installment</p>
                <span > {singleData?.expired_time}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Paid</p>
                <span >{singleData?.status === 2 ? "paid" : ""} {singleData?.status === 1 ? "unpaid" : ""}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Status</p>
                {singleData?.status === 0 ? <span class="badge bg-warning ">Pending</span>  : singleData?.status === 3 ?  <span class="badge bg-danger">Reject</span>  :  <span class="badge bg-primary">Approved</span>}
              </div>
              <div className="history-details-modal-item">
                <p>Date</p>
                <span>
                  {dateFormat(singleData?.createdAt, "mmm,")}&nbsp;
                  {dateFormat(singleData?.createdAt, "dS")}&nbsp;
                  {dateFormat(singleData?.createdAt, "yyyy")}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center"></Modal.Footer>
      </Modal>
    );
  }
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <ModalComponent show={show} onHide={() => setShow(false)} />
      </>
    );
  }
};

export default LoanHistoryDetails;
