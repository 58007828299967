import React, { useEffect, useState } from "react";
import Metals from "./Metals";

const MetalsPage = () => {
  const [Metalsdata, setMetalsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/trade/market/metals/mobile/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setMetalsData(data.data);
        setLoading(false);
      });
  }, []);

  const [Energisesdata, setEnergisesData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.prospectstreetx.com/api/user/trade/market/energises/mobile/filter/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setEnergisesData(data.data);
      });
  }, []);



  if (loading) {
    return;
  }

  return (
    <>
      <Metals
        MetalsData={Metalsdata}
        Energisesdata={Energisesdata}
      ></Metals>
    </>
  );
};

export default MetalsPage;
