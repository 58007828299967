import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import MobileDepositHistory from "../MobileDepositHistory/MobileDepositHistory";
import GatewaysSelectForm from "./GatewaysSelectForm";
const MobileCryptoDeposit = ({ setUpdateData }) => {
  const { authUser } = useContext(AuthContext);
  const [GatewaysData, setGatewaysData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/payment/gateways/manual/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setGatewaysData(data.data);
      });
  }, []);

  const [SingleGatewaysData, setSingleGatewaysData] = useState([]);

  const SingleGatewaysHandle = (event) => {
    const id = event.target.value;
    if (id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/payment/gateways/manual/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleGatewaysData(data.data);
        });
    }
  };

  const [dataValue, setDataValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const [userImage, setUserImage] = useState("");
  const refSubmitDis = useRef();

  const handleImage = (e) => {
    setUserImage(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const storeData = {
      ...dataValue,
      user_id: authUser?._id,
      screenshot: userImage,
      network_id: SingleGatewaysData?._id,
    };

    if (storeData.screenshot === "") {
      toast.error(`please choose your image`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      refSubmitDis.current.removeAttribute("disabled");
    } else if (!storeData?.network_id) {
      toast.error(`Network field is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `https://api.prospectstreetx.com/api/user/payment/gateways/manual/deposit/store`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          if (data?.success) {
            setUpdateData(data);
          }
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          console.log(error);
        });
    }
  };

  const handleCopy = () => {
    if (SingleGatewaysData) {
      navigator.clipboard.writeText(SingleGatewaysData?.wallet_address);
      toast.success(`copies wallets address!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.success(`something is wrong`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [depositShow, setDepositShow] = useState("Crypto");
  const handleDepositShow = (data) => {
    setDepositShow(data);
  };
  
  return (
    <>
      <section className="mobile-py-20-280  mobile-deposit-method">
        <div className="user-profile-area mobile-user-total-value pb-3">
          <div className="container">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row gutters">
                      <form onSubmit={handleSubmitData}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="mb-3 text-primary text-center ">Deposit</h6>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Select Deposit Method
                            </label>
                            <select
                              name="GatewayId"
                              onChange={(e) => handleDepositShow(e.target.value)}
                              className="form-select"
                              required
                            >
                              <option value="Crypto" selected>
                                Crypto Wallet Transfer
                              </option>
                              <option value="Bank">Bank Wire or P2P</option>
                            </select>
                          </div>
                        </div>

                        {depositShow === "Crypto" && (
                          <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select Network
                                </label>
                                <select
                                  name="GatewayId"
                                  onChange={SingleGatewaysHandle}
                                  className="form-select"
                                  required
                                >
                                  <option selected disabled>
                                    select
                                  </option>

                                  {GatewaysData.map((data) => {
                                    return (
                                      <GatewaysSelectForm data={data} key={data?._id} />
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            {SingleGatewaysData?.wallet_address ? (
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group">
                                  <div className="gateways-data-area">
                                    <div className="deposit-qr-code-area">
                                      <div className="deposit-qr-code">
                                        <span className="deposit-qr-code__angle deposit-qr-code__angle--top"></span>
                                        <LazyLoadImage
                                          alt="img"
                                          effect="blur"
                                          src={`https://api.prospectstreetx.com/${SingleGatewaysData?.image}`}
                                        />
                                        <span className="deposit-qr-code__angle deposit-qr-code__angle--bottom"></span>
                                      </div>
                                      <div className="deposit-scan-me">
                                        <h6>Scan Me</h6>
                                      </div>
                                    </div>

                                    <div className="wallet-address">
                                      <span>Wallet Address</span>
                                      <p>
                                        {SingleGatewaysData?.wallet_address}
                                      </p>
                                      <p
                                        onClick={handleCopy}
                                        className="wallet-address-copy"
                                      >
                                        <i className="fa-solid fa-copy"></i>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Amount
                                </label>
                                <input
                                  type="number"
                                  name="amount"
                                  onBlur={handleInputBlur}
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  aria-describedby="emailHelp"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Transaction screenshot
                                </label>
                                <input
                                  type="file"
                                  name="amount"
                                  onChange={handleImage}
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  aria-describedby="emailHelp"
                                  required
                                  accept=" .jpg,  .jpeg,  .png, "
                                />
                                <pre className="text--base mt-1">
                                  Supported mimes: jpg,jpeg,png
                                </pre>
                              </div>
                            </div>
                            <div className="row gutters">
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    id="submit"
                                    className="user-btn1  px-5"
                                    ref={refSubmitDis}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {depositShow === "Bank" && (
                          <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Please contact with our customer support to
                                  get the details.
                                  <br />
                                  <br />
                                  Thank You.
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileDepositHistory />
      </section>
    </>
  );
};

export default MobileCryptoDeposit;
