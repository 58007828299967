import React, { useEffect, useState } from "react";
import Stocks from "./Stocks";
const StocksPage = () => {
  const [StocksData, setStocksData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/trade/market/stocks/mobile/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setStocksData(data.data);
        setLoading(false);
      });
  }, []);
 
  const [Indicesdata, setIndicesData] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/trade/market/indices/mobile/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setIndicesData(data.data);
      });
  }, []);


  if (loading) {
    return;
  }
  return (
    <>
      <Stocks
        StocksData={StocksData}
        Indicesdata={Indicesdata}
      ></Stocks>
    </>
  );
};

export default StocksPage;
