import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SingleItem = ({ data }) => {
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice, data]);

  const fetchData = async () => {
    if (data?.symbol) {
      fetch(
        `https://api.prospectstreetx.com/api/user/new/coin/price/view/${data?.symbol}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLivePrice(data.data);
          if (data.data) {
            setLivePrice(data?.data[0]);
            setLoading(false);
          }
        });
    }
  };
  if (loading) {
    return;
  }
  return (
    <>
      <Link to={`/user/new/coin/${data?._id}`}>
        <div className="bg-white  hot-coin">
          <div className="wrapper">
            <div className="d-flex align-items-center gap-2">
              <div className="mr-2">
                <LazyLoadImage
                  effect="blur"
                  alt="img"
                  src={`https://api.prospectstreetx.com/${data?.image}`}
                  className="hot-coin-img"
                />
              </div>
              <div className="flex-grow-1">
                <div className="text-dark font-light">
                  <span className="font-medium text-lg">{data?.name}</span>
                  <span className="text-base">/{data?.symbol}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="exchange">
            <div className="text-base font-light text-up">
              {livePrice?.close}$
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SingleItem;
