import React, { useContext, useEffect, useState } from "react";
import "./MobileAssets.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../Loader/Loader";
const MobileAssets = () => {
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/assets/balance/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id]);

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  return (
    <>
      <section className="assets-area">
        <div className="assets-user-balance-area">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>
                  Total Balance <i className="fa-solid fa-eye"></i>
                </h4>
                <div className="assets-user-balance">
                  <h5>{!isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}</h5>
                  <span>
                    USDT <i className="fa-solid fa-caret-down"></i>
                  </span>
                </div>
                <div className="assets-user-sub-balance">
                  <p>≈ ${!isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}</p>
                </div>
              </div>
              <Link to="/user/assets/exchange" className="user-btn1">
                Exchange
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="assets-link-area">
            <Link to="/user/assets/deposit">
            <i
                  className="fa-solid fa-money-bill-1-wave"
                  style={{ color: "#536DFE" }}
                ></i>
              <p>Deposit</p>
            </Link>
            <Link to="/user/assets/withdraw">
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
              <p>Withdraw</p>
            </Link>
            <Link to="/user/new/coin">
            <i
                  className="fa-solid fa-coins"
                  style={{ color: "green" }}
                ></i>
              <p>New Coin</p>
            </Link>
            <a href="https://www.simplex.com" target="_blank" rel="noopener noreferrer">
              <i className="fa-solid fa-shop-lock"  style={{ color: "#ff00f2" }}></i>
              <p>Buy Coin</p>
            </a>
         
          </div>

          <div className="assets-bottom-balance-area">
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Bitcoin</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Bitcoin
                      ? parseFloat(data?.Bitcoin?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Bitcoin
                      ? parseFloat(data?.Bitcoin?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+{data?.Bitcoin
                      ? parseFloat(data?.Bitcoin?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)} USDT (+{data?.Bitcoin
                      ? parseFloat(data?.Bitcoin?.percentage).toFixed(2)
                      : parseFloat(0).toFixed(2)}%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Ethereum</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Ethereum
                      ? parseFloat(data?.Ethereum?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Ethereum
                      ? parseFloat(data?.Ethereum?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+ {data?.Ethereum
                      ? parseFloat(data?.Ethereum?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)} USDT (+ {data?.Ethereum
                        ? parseFloat(data?.Ethereum?.percentage).toFixed(2)
                        : parseFloat(0).toFixed(2)}%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Solana</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Solana
                      ? parseFloat(data?.Solana?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Solana
                      ? parseFloat(data?.Solana?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+ {data?.Solana
                      ? parseFloat(data?.Solana?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)} USDT (+ {data?.Solana
                        ? parseFloat(data?.Solana?.percentage).toFixed(2)
                        : parseFloat(0).toFixed(2)}0%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Gold</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Gold
                      ? parseFloat(data?.Gold?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Gold
                      ? parseFloat(data?.Gold?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+{data?.Gold
                      ? parseFloat(data?.Gold?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)} USDT (+{data?.Gold
                        ? parseFloat(data?.Gold?.percentage).toFixed(2)
                        : parseFloat(0).toFixed(2)}%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Tether</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Tether
                      ? parseFloat(data?.Tether?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Tether
                      ? parseFloat(data?.Tether?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+ {data?.Tether
                      ? parseFloat(data?.Tether?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)} USDT (+ {data?.Tether
                        ? parseFloat(data?.Tether?.percentage).toFixed(2)
                        : parseFloat(0).toFixed(2)}%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAssets;
