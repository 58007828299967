import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import logo from "./../../../logo.jpg";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Login.css";
import FormData from "form-data";

const LoginForm = () => {
  const { LoginWithEmail } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const userFrom = location.state?.from?.pathname || "/dashboard";

  const [errorMessage, setErrorMessage] = useState({});
  const refSubmitDis = useRef();

  const handleRegister = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);

    fetch("https://api.prospectstreetx.com/api/user/auth/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(InpuData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setErrorMessage(data);
          refSubmitDis.current.removeAttribute("disabled");
        } else {
          const user = data;
          localStorage.setItem("ID", JSON.stringify(user.data));
          const expires = new Date(
            Date.now() + 365 * 24 * 60 * 60 * 1000
          ).toUTCString();
          document.cookie = `gffex_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires}; path=/`;
          event.target.reset();
          if (user.data) {
            LoginWithEmail(user.data);
            navigate(userFrom, { replace: true });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const [type, setType] = useState("password");
  const eyeActiveRef = useRef();

  const showPassword = () => {
    if (type === "password") {
      setType("text");
      eyeActiveRef.current.classList.add("active");
    } else {
      setType("password");
      eyeActiveRef.current.classList.remove("active");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <section className="body-section">
        <section className="login-section">
          <div className="login-area">
            <div className="login-card">
              <div className="login-img">
                <Link to="/">
                  <LazyLoadImage alt="img" effect="blur" src={logo} />
                </Link>
              </div>
              {errorMessage?.success === false ? (
                <span className="text-danger text-start ms-2 pb-5 d-block">
                  {errorMessage.message}
                </span>
              ) : (
                ""
              )}
              <div className="login-form-area">
                <form onSubmit={handleRegister}>
                  <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="input-group toggle-eye ">
                    <label htmlFor="password">Password</label>
                    <input
                      type={type}
                      name="password"
                      id="password"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={showPassword}
                      ref={eyeActiveRef}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </div>

                  <div className="remember">
                    <input type="checkbox" name="" id="remember" />
                    <label htmlFor="remember"> Remember Me</label>
                  </div>

                  <div className="forget">
                    <Link to="/forgot/password">Forget password? </Link>
                  </div>

                  <div className="submit-btn-area">
                    <button
                      type="submit"
                      ref={refSubmitDis}
                      className="button-1 submit-btn"
                    >
                      Login
                    </button>
                    <Link to="/register" className="button-1 submit-btn">
                      Signup
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default LoginForm;
