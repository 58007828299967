import React, { useEffect, useState } from "react";
import Energises from "./Energises";

const EnergisesPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/trade/market/forex/mobile/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return;
  }

  return (
    <>
      <Energises EnergisesData={data}></Energises>
    </>
  );
};

export default EnergisesPage;
