import React, { useEffect,  useState } from "react";
import NoticeModal from "./NoticeModal";
import axios from "axios";

const Notices = ({ ToggleAnnouncementSidebar }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.prospectstreetx.com/api/frontend/home/notices/view`)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log("API call error:", err?.message);
      });
  }, []);

  const [singleDataId, setSingleDataId] = useState(null);

  const [show, setShow] = useState(false);

  const NoticeModalShow = (id) => {
    setSingleDataId(id);
    setShow(true);
    ToggleAnnouncementSidebar();
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center ">
            <h6>Announcemen</h6>
            <div className="close-btn" onClick={ToggleAnnouncementSidebar}>
              <i className="fa fa-times"></i>
            </div>
          </div>
        </div>
        <div className="announcement">
          {data?.length !== 0 ? (
            data.map((data, index) => {
              if (data) {
                return (
                  <div key={data?._id}>
                    <p onClick={() => NoticeModalShow(data?._id)}>
                      {data?.name}
                    </p>
                  </div>
                );
              }
            })
          ) : (
            <div className="text-center pt-4">
              <span className="text-muted text-center">
                No data available
              </span>
            </div>
          )}

        </div>
      </div>

      {(() => {
        if (show) {
          return (
            <NoticeModal
              singleDataId={singleDataId}
              setShow={setShow}
              show={show}
            ></NoticeModal>
          );
        }
      })()}
    </>
  );
};
export default Notices;
