import React, { useEffect, useRef, useState } from "react";
import "./MobileContact.css";
import tel from "./telegram.webp";
import mas from "./massenger.png";
import live from "./live.jpg";
import whatsapp from "./whatsapp.png";
import ChatPage from "../../../LiveChatsPage/ChatPage/ChatPage";
import Loader from "../../../Loader/Loader";
import axios from "axios";
const MobileContact = () => {
  const [chatPage, setChatPage] = useState(false);
  const ChatPageShowRef = useRef();

  const ChatPageShow = () => {
    ChatPageShowRef.current.style.display = "block";
    setChatPage(true);
  };
  const ChatPageHide = () => {
    ChatPageShowRef.current.style.display = "none";
    setChatPage(false);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://api.prospectstreetx.com/api/admin/site/setting/social/support/view`
      )
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("API call error:", err?.message);
      });
  }, []);

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <section className="mobile-contact-section">
        <div className="container">
          <div className="card mb-3">
            <div className="card-content">
              <h5>{data?.dis}</h5>
            </div>
          </div>
          {data?.whatsapp_link && data?.whatsapp_name ? (
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Whatsapp Support</h4>
                  </div>
                  <div className="logo">
                    <img src={whatsapp} alt="" width="40px" height="40px" />
                  </div>
                  <a
                    href={data?.whatsapp_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data?.whatsapp_name}
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.telegram_link && data?.telegram_name ? (
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Telegram Support</h4>
                  </div>
                  <div className="logo">
                    <img src={tel} alt="" width="40px" height="40px" />
                  </div>
                  <a
                    href={data?.telegram_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data?.telegram_name}
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.email && (
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Email Consultation :</h4>
                    <span>{data?.email}</span>
                  </div>
                  <div className="logo">
                    <img src={mas} alt="" width="40px" height="40px" />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="card mb-3">
            <div className="card-content">
              <div className="d-flex justify-content-between align-items-center">
                <div className="name">
                  <h4>Live Chat</h4>
                </div>
                <div className="logo">
                  <img src={live} alt="" width="40px" height="40px" />
                </div>
                <button onClick={ChatPageShow}>PCX Live Chat</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style={{ display: "none" }} ref={ChatPageShowRef}>
        {(() => {
          if (chatPage) {
            return <ChatPage ChatPageHide={ChatPageHide}></ChatPage>;
          }
        })()}
      </div>
    </>
  );
};

export default MobileContact;
