import React, { useEffect,  useState } from "react";
import Modal from "react-bootstrap/Modal";

const ComplateTrade = (props) => {
  const { data, marketData, complateTradeModalShow } = props;

  const [ResultsData, setResults] = useState([]);
  
  const time = (1000 * parseFloat(data?.Time)) + 3000

  useEffect(() => {
    setTimeout(() => {
      fetch(`https://api.prospectstreetx.com/api/user/trade/log/single/view/${data?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
         setResults(data?.data);
        });
    }, time ) ;

  }, [data, complateTradeModalShow]);

  if (ResultsData?.Result) {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          className="mobile-trade-modal-section trade-results-modal-area posce-trade-modal mobile-trade-results-area"
          
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {marketData?.Name ? marketData?.Name : marketData?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h1
              class="text-center"
              id="resultAmount"
              style={{ color: "#8c88ff" }}
            >
              {ResultsData?.Result === "Loss" ? 0 + " Profit USDT" : ""}
              {ResultsData?.Result === "Win"
                ? "+" +  parseFloat(ResultsData?.Result_Amount) -
                  parseFloat(ResultsData?.Amount) +
                  " Profit USDT"
                : ""}
              {ResultsData?.Result === "Draw"
                ? parseFloat(ResultsData?.Result_Amount) -
                  parseFloat(ResultsData?.Amount) +
                  " Profit USDT"
                : ""}
            </h1>
            <p class="text-center mt-2 mb-5" id="resultMessage">
              Completion of maturity settlement
            </p>

            <div class="result-details mt-4">
              <div className="trade-results">
                <p>Selection period:</p>
                <p>{ResultsData?.Time}s</p>
              </div>
              <div className="trade-results">
                <p>Current price:</p>
                <p>{ResultsData?.Crypto_price}</p>
              </div>
              <div className="trade-results">
                <p>Direction:</p>
                <p>{ResultsData?.HighLow}</p>
              </div>
              <div className="trade-results">
                <p>Amount:</p>
                <p>{ResultsData?.Amount}</p>
              </div>
              <div className="trade-results">
                <p> Post amount:</p>
                <p>{ResultsData?.Result_Amount}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default ComplateTrade;
